import React, { useCallback, useEffect } from 'react'

import { interWbSession } from 'inter-webview-bridge'

import { Babi, Button, Icon, Text } from '@inter/inter-ui'
import { RouteComponentProps } from '@reach/router'

import { BoxTextAligment, Content, PlansCardList, BackgroundPending } from './styles'
import HeroImage from './assets/images/HeroImageHome.png'
import useAsync from '../../hooks/useAsync'
import { Landing } from '../../types/LandingPage'
import HomeSkeleton from '../HomeSkeleton'
import { Error } from '../Error'
import { AppContainer } from '../../styles/GlobalStyles'
import { AppBar } from '../../components/AppBar'
import { bridgeNative } from '../../App'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { InterPassPlan } from '../../components/InterPassPlan'
import { BenefitLP } from '../../components/BenefitLP'
import { SubscriptionsWebview } from '../../types/Subscription'

interface HomeSignedProps extends RouteComponentProps {
  termsOfUse?: string
  subscriptionData?: SubscriptionsWebview
}

export const LandingPage: React.FC<HomeSignedProps> = ({ termsOfUse, subscriptionData }) => {
  const [dataLandingPage, getDataLandingPage] = useAsync<Landing>('get')

  useEffect(() => {
    window.scrollTo(0, 0)
    interWbSession.requestAnalytics('C_InterX_HomeLP_DC_Inicio', {
      tela: 'LP',
    })
  }, [])

  const fetchLandingPage = useCallback(async () => {
    await getDataLandingPage(`app-pf/interprime/v1/home`, undefined, {
      'x-inter-prime-status': 'false',
    })
  }, [getDataLandingPage])

  useEffect(() => {
    fetchLandingPage()
  }, [fetchLandingPage])

  const handleTermsOfUse = useCallback(() => {
    bridgeNative.execute({
      action: 'sendToTermsSubscription',
      metadata: {
        pdfUrl: termsOfUse,
      },
    })
  }, [termsOfUse])

  const openBabi = useCallback(() => {
    interWbSession.requestAnalytics('C_CD_HomeApp_T_Babi')
    bridgeNative.execute({ action: 'sendToBabi' })
  }, [])

  if (dataLandingPage.isError) {
    return <Error />
  }

  if (dataLandingPage.isPending) {
    return <HomeSkeleton />
  }

  if (dataLandingPage.isSuccess) {
    return (
      <>
        <AppBar titleRoute="Inter Pass" goBackNative />
        <AppContainer paddingTopRem={2.5}>
          <Content className="hero-image-content">
            <img alt="hero_image" src={HeroImage} />
          </Content>
          <Content>
            <BoxTextAligment>
              <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
                Você com ainda mais vantagens no Inter Pass
              </Text>
              <Text color="typography" colorWeight={400} variant="body-3">
                Com uma assinatura, você tem benefícios em vários produtos do Inter, incluindo
                cashback no frete de produtos do Inter Shop!
              </Text>
            </BoxTextAligment>
            {subscriptionData ? (
              <BackgroundPending>
                <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
                  <Icon name="warning" size={16} color="var(--alert500)" />
                </div>
                <div>
                  <div className="alert-text">
                    <Text color="typography" colorWeight={500} variant="body-3" bold>
                      {subscriptionData.statusTitle}
                    </Text>
                    <Text color="typography" colorWeight={500} variant="caption-1">
                      {subscriptionData.statusText}
                    </Text>
                  </div>
                </div>
              </BackgroundPending>
            ) : (
              <PlansCardList>
                {dataLandingPage.data?.sections[0].plans?.map((plan) => (
                  <InterPassPlan
                    {...(Number(dataLandingPage?.data?.sections[0]?.plans?.length) > 2 && {
                      style: { minWidth: '45%' },
                    })}
                    {...plan}
                  />
                ))}
              </PlansCardList>
            )}
          </Content>
          <Content>
            <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
              Quanto mais você usar, mais cashback você tem
            </Text>
            <div style={{ width: '100%', marginTop: '2rem' }}>
              {dataLandingPage.data?.sections
                .find((c) => c.sectionType === 'PRIME_BENEFITS')
                ?.benefits?.map((props) => (
                  <>
                    <BenefitLP {...props} subscriptionData={subscriptionData} />
                  </>
                ))}
            </div>
          </Content>
          <Content style={{ marginBottom: '6.5rem' }}>
            <Button
              style={{
                display: 'flex',
                alignSelf: 'flex-start',
                padding: 0,
                marginBottom: '1.5rem',
                width: 'auto',
              }}
              variant="link"
              onClick={handleTermsOfUse}
            >
              <Text color="primary" colorWeight={500} variant="caption-1" bold>
                Ver Termos e Condições
              </Text>
            </Button>
            <Babi onClick={openBabi} />
          </Content>
          <AssignBottomButton screenName="Inter Pass" disabled={Boolean(subscriptionData)} />
        </AppContainer>
      </>
    )
  }

  return null
}
