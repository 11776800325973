import React from 'react'

import { Skeleton } from '@inter/inter-ui'

import { Container } from './styles'

const HomeSkeleton = () => (
  <>
    <Skeleton width="100%" height="60px" />
    <Skeleton width="100%" height="250px" style={{ marginTop: '10px' }} />
    <Container>
      <Skeleton width="100%" height="80px" />
      <Skeleton width="100%" height="60px" />
      <Skeleton width="100%" height="40px" style={{ marginTop: '10px' }} />
      <Skeleton width="100%" height="40px" style={{ marginTop: '10px ' }} />
    </Container>
    <Container>
      <Skeleton width="80%" height="30px" style={{ marginBottom: '10px' }} />
      <Skeleton width="80%" height="30px" style={{ marginBottom: '10px' }} />
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
      <div style={{ margin: '10px 0' }}>
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="40px" />
      </div>
    </Container>
    <Container>
      <Skeleton width="100%" height="40px" style={{ margin: '10px 0' }} />
      <Skeleton width="100%" height="80px" style={{ marginBottom: '10px ' }} />
    </Container>
  </>
)

export default HomeSkeleton
