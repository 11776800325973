import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.hero-image-content {
    padding: 0;
  }

  &:first-child {
    margin-bottom: 0;
  }
  & > * {
    width: 100%;
  }

  &:last-child {
    padding-top: 1rem;
    border-top: 1px solid var(--gray100);
  }
`

export const BoxTextAligment = styled.div`
  display: grid;
  gap: 1rem;
`

export const DeliveryCashback = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 0.75rem;
    border-radius: 50%;
    background: var(--success100);
  }

  & > :last-child span {
    color: var(--success500);
  }
`

export const AdvantageBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;

  &:first-child {
    margin-top: 1.5rem;
  }
`

export const AdvantageBoxIcon = styled.div`
  display: flex;
  align-items: flex-start;
`

export const AdvantageBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1.5rem;
`

export const AdvantageBoxTitle = styled.div`
  margin-bottom: 0.25rem;
`

export const AdvantageBoxText = styled.div``

export const Carousel = styled.div`
  display: flex;
  width: 100vw;
  overflow: auto;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;

  & div {
    width: 100%;
    min-width: 19rem;
    margin-right: 0.75rem;

    & img {
      width: 100%;
      height: auto;
    }
  }
`

export const ToolsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 0px solid var(--gray100);
  border-bottom-width: 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    border-width: 0;
  }
`

export const ToolsBoxIcon = styled.div`
  background-color: var(--white100);
  border: 1px solid var(--gray200);
  display: flex;
  padding: 0.5rem;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
`

export const ToolsBoxContent = styled.div`
  display: grid;
  gap: 0.5rem;
  padding: 0.5rem 0.875rem;
  width: 100%;
`
