export const isStatus4xx = (status?: number): boolean => {
  if (!status) return false
  if (status >= 400 && status < 500) return true
  return false
}

export const isStatus2xx = (status?: number): boolean => {
  if (!status) return false
  if (status >= 200 && status < 300) return true
  return false
}

export const isNotAuthenticated = (status?: number): boolean => {
  if (!status) return false
  if (status === 401) return true
  return false
}

export const isNotAuthorized = (status?: number): boolean => {
  if (!status) return false
  if (status === 403) return true
  return false
}
