import styled from 'styled-components'

export const BenefitCard = styled.div`
  width: 100%;
  background-color: var(--gray100);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 1rem;
`

export const BenefitCardHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
`

export const BenefitCardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`

export const BenefitCardContent = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`
