import React, { useCallback } from 'react'

import { interWbSession } from 'inter-webview-bridge'

import { Button } from '@inter/inter-ui'

import { AssignBottomButtonBox } from './styles'
import { bridgeNative } from '../../App'

interface Props extends React.HTMLAttributes<unknown> {
  screenName: string
  isBenefit?: boolean
  disabled?: boolean
  hasSubscription?: boolean
  deeplink?: string
}

export const AssignBottomButton: React.FC<Props> = ({
  screenName,
  disabled,
  isBenefit,
  hasSubscription,
  deeplink,
  ...props
}) => {
  const assignInterPass = useCallback((screen_name, is_benefit) => {
    if (is_benefit) {
      interWbSession.requestAnalytics('C_InterX_ResumoProd_T_Assinar', {
        content_benef: screen_name,
        content_name: 'Assinar Inter Pass',
        assinante: 'não',
      })
    } else {
      interWbSession.requestAnalytics('C_InterX_LP_T_BotaoAssinar', {
        tela: screen_name,
      })
    }
    bridgeNative.execute({
      action: 'sendToSubscription',
      metadata: { productId: 'INTER_PRIME' },
    })
  }, [])

  const openDeepLinkPage = useCallback((deeplink_url, screen_name?) => {
    if (screen_name) {
      interWbSession.requestAnalytics('C_InterX_ResumoProd_T_Assinar', {
        content_benef: screen_name,
        content_name: 'Acessar',
        assinante: 'sim',
      })
    }
    bridgeNative.execute({
      action: 'openDeeplink',
      metadata: {
        url: deeplink_url,
      },
    })
  }, [])

  return (
    <AssignBottomButtonBox {...props}>
      {hasSubscription ? (
        <Button onClick={() => openDeepLinkPage(deeplink, screenName)} disabled={disabled}>
          Acessar
        </Button>
      ) : (
        <Button onClick={() => assignInterPass(screenName, isBenefit)} disabled={disabled}>
          Assinar Inter Pass
        </Button>
      )}
    </AssignBottomButtonBox>
  )
}
