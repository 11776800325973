export interface ApiError<T = Record<string, unknown>> {
  code: string
  message: string
  additionalMessage: string
  metadata?: Partial<T>
}

export class ApiErrors<T = ApiError> extends Error {
  public errors: ApiError<T>[] = []

  public statusCode!: number

  public method!: string

  constructor(err: Partial<ApiErrors<T>>) {
    super(err.message)
    Object.assign(this, err)
  }
}
