import React, { useEffect } from 'react'

import { Text } from '@inter/inter-ui'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'

import { BoxTextAligment, Content, Separator, Condition } from './styles'
import HeroImage from './assets/images/HeroImageMaisProtegido.png'
import LocationBlackIcon from '../../assets/icons/LocationBlack.svg'
import ShoppingBagBlackIcon from '../../assets/icons/ShoppingBagBlack.svg'
import SmartphoneBlackIcon from '../../assets/icons/SmartphoneBlack.svg'
import { AppBar } from '../../components/AppBar'
import { AppContainer } from '../../styles/GlobalStyles'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { SubscriptionsWebview } from '../../types/Subscription'

export const MaisProtegido: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const location = useLocation() as WindowLocation<{
    hasSubscription: boolean
    deeplink: string
    subscriptionData?: SubscriptionsWebview
  }>
  return (
    <>
      <AppBar titleRoute="Proteção no Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              Aproveite o seguro cartão + protegido
            </Text>
            <Text
              color="typography"
              colorWeight={400}
              variant="body-3"
              style={{ marginTop: '0.5rem' }}
            >
              Com ele você fica mais protegido em caso de perda, roubo, furto, saque ou compra sob
              ameaça até o limite de R$3.000,00.
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <Separator />
        </Content>
        <Content>
          <BoxTextAligment style={{ gap: '0.5rem' }}>
            <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
              Suas coberturas
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Essas são as coberturas que você garante no seu seguro Cartão + Protegido Standard:
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <ul style={{ paddingLeft: '1.5rem' }}>
            <li>
              <BoxTextAligment style={{ gap: '0.5rem' }}>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  Perda, roubo ou furto do cartão.
                </Text>
                <Text color="typography" colorWeight={400} variant="body-3">
                  Garante em caso de perda, roubo ou furto do cartão um estorno no valor de até
                  R$3.000,00.
                </Text>
              </BoxTextAligment>
            </li>
            <li>
              <BoxTextAligment style={{ gap: '0.5rem', marginTop: '1.5rem' }}>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  Saque ou compra sob coação
                </Text>
                <Text color="typography" colorWeight={400} variant="body-3">
                  Garante em caso de saque ou compra sob coação um estorno no valor de até
                  R$3.000,00.
                </Text>
              </BoxTextAligment>
            </li>
          </ul>
        </Content>
        <Content>
          <Separator />
        </Content>
        <Content>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Beneficios
          </Text>
        </Content>
        <Content style={{ marginBottom: '6.5rem' }}>
          <Condition>
            <div>
              <img
                src={LocationBlackIcon}
                alt="ícone de localização"
                color="var(--primary500)"
                width="24"
              />
            </div>
            <div>
              <Text color="typography" colorWeight={400} variant="body-3">
                Atendimento Personalizado
              </Text>
            </div>
          </Condition>

          <Condition>
            <div>
              <img
                src={ShoppingBagBlackIcon}
                alt="ícone de sacola"
                color="var(--primary500)"
                width="24"
              />
            </div>
            <div>
              <Text color="typography" colorWeight={400} variant="body-3">
                Sorteio mensal de R$5.000,00
              </Text>
            </div>
          </Condition>

          <Condition>
            <img
              src={SmartphoneBlackIcon}
              alt="ícone de smartphone"
              color="var(--primary500)"
              width="24"
            />
            <div>
              <Text color="typography" colorWeight={400} variant="body-3">
                Apenas para produtos comprados em lojas dentro do aplicativo do Inter
              </Text>
            </div>
          </Condition>
        </Content>
        <AssignBottomButton
          screenName="Mais Protegido"
          isBenefit
          deeplink={location.state.deeplink}
          hasSubscription={location.state.hasSubscription}
          disabled={Boolean(location.state?.subscriptionData)}
        />
      </AppContainer>
    </>
  )
}
