import * as React from 'react'

import { interWbNavigate } from 'inter-webview-bridge'

import { useNavigate } from '@reach/router'

import { bridgeNative } from '../App'

interface GoBackProps {
  goBackNative?: boolean
}

const useGoBack = ({ goBackNative }: GoBackProps) => {
  const navigate = useNavigate()

  const goBack = React.useCallback(() => {
    if (goBackNative) {
      interWbNavigate.requestGoBack()
    } else {
      navigate('/')
    }
  }, [goBackNative, navigate])

  React.useEffect(() => {
    bridgeNative.addBackListener(goBack)
    return () => {
      bridgeNative.removeBackListener(goBack)
    }
  }, [goBack])

  return goBack
}

export default useGoBack
