import React, { useCallback } from 'react'

import { interWbSession } from 'inter-webview-bridge'

import { Button, Text } from '@inter/inter-ui'
import { navigate } from '@reach/router'

import { BenefitCard, BenefitCardHeader, BenefitCardIcon, BenefitCardContent } from './styles'
import CashbackUpBlackIcon from '../../assets/icons/CashbackUpBlack.svg'
import CreditCardBlackIcon from '../../assets/icons/CreditCardBlack.svg'
import ForkKnifeBlackIcon from '../../assets/icons/ForkKnifeBlack.svg'
import UmbrellaBlackIcon from '../../assets/icons/UmbrellaBlack.svg'
import InvestmentBlackIcon from '../../assets/icons/InvestmentBlack.svg'
import CashbackBlackIcon from '../../assets/icons/CashbackBlack.svg'
import FreteBlackIcon from '../../assets/icons/FreteBlack.svg'

interface Props extends React.HTMLAttributes<unknown> {
  captionHomePage: string
  titleHomePage: string
  description: string
  benefitName: string
  landingPageLink: string
  deepLink: string
}

export const BenefitHome: React.FC<Props> = ({
  captionHomePage,
  titleHomePage,
  description,
  benefitName,
  landingPageLink,
  deepLink,
}) => {
  const navigateBenefit = useCallback((lp_link, benefit_name?, deeplink_url?) => {
    if (benefit_name) {
      interWbSession.requestAnalytics('C_InterX_Home_T_VerBeneficio', {
        content_card: benefit_name,
      })
    }
    navigate(`/${lp_link}`, {
      state: { hasSubscription: true, deeplink: deeplink_url },
    })
  }, [])

  const icons: { [key: string]: string } = {
    cartaoProtegido: CreditCardBlackIcon,
    investPro: InvestmentBlackIcon,
    segurosDefault: UmbrellaBlackIcon,
    interCel: CashbackBlackIcon,
    cashbackTurbinado: CashbackUpBlackIcon,
    cashbackFrete: FreteBlackIcon,
    duoGourmet: ForkKnifeBlackIcon,
    default: CashbackBlackIcon,
  }

  return (
    <BenefitCard>
      <BenefitCardHeader>
        <BenefitCardIcon>
          <img
            src={icons[benefitName] || icons.default}
            alt="ícone de hamburguer"
            color="var(--primary500)"
            width="24"
          />
        </BenefitCardIcon>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text color="typography" colorWeight={500} variant="caption-1">
            {captionHomePage}
          </Text>
          <Text color="typography" colorWeight={500} variant="body-2" bold>
            {titleHomePage}
          </Text>
        </div>
      </BenefitCardHeader>
      <BenefitCardContent>
        <Text color="typography" colorWeight={400} variant="caption-1">
          {description}
        </Text>
      </BenefitCardContent>
      <Button
        variant="secondary"
        style={{ width: '100%' }}
        onClick={() => navigateBenefit(landingPageLink, titleHomePage, deepLink)}
      >
        Saiba mais
      </Button>
    </BenefitCard>
  )
}
