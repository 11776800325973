import React, { useCallback, useEffect, useState } from 'react'

import '@inter/inter-ui/dist/inter-ui.css'

import { RouteComponentProps } from '@reach/router'

import HomeSkeleton from '../HomeSkeleton'
import { HomeSigned } from '../HomeSigned'
import { LandingPage } from '../LandingPage'
import { bridgeNative } from '../../App'
import { SubscriptionsWebview } from '../../types/Subscription'

type WebviewResponse = {
  hasSubscription: boolean
  subscriptions: SubscriptionsWebview[]
  termsOfUse: string
}

export const Home: React.FC<RouteComponentProps> = () => {
  const [response, setResponse] = useState<WebviewResponse | null | undefined>(null)

  const fetchSubscribeData = useCallback(async () => {
    const res: WebviewResponse = await bridgeNative.execute({
      action: 'requestSubscriptionPages',
      metadata: { productId: 'INTER_PRIME' },
    })
    setResponse(res)
  }, [])

  useEffect(() => {
    fetchSubscribeData()
  }, [fetchSubscribeData])

  if (response === null) {
    return <HomeSkeleton />
  }

  if (
    response !== null &&
    response?.hasSubscription &&
    (response.subscriptions[0].status === 'ACTIVE' ||
      response.subscriptions[0].status === 'CANCELED' ||
      response.subscriptions[0].status === 'PENDING_PAYMENT')
  ) {
    return (
      <HomeSigned termsOfUse={response?.termsOfUse} subscriptionData={response.subscriptions[0]} />
    )
  }

  if (
    response !== null &&
    response?.hasSubscription &&
    response.subscriptions[0].status === 'PENDING'
  ) {
    return (
      <LandingPage subscriptionData={response.subscriptions[0]} termsOfUse={response?.termsOfUse} />
    )
  }

  if (response !== null && !response?.hasSubscription) {
    return <LandingPage termsOfUse={response?.termsOfUse} /> // LandingPage Customizada
  }
  return null
}
