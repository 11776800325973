import React, { useEffect } from 'react'

import { Text } from '@inter/inter-ui'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'

import {
  BoxTextAligment,
  Content,
  DeliveryCashback,
  StepsToEnjoyList,
  StepToEnjoyItem,
  Separator,
  Condition,
  BannerInterShop,
} from './styles'
import HeroImage from './assets/images/HeroImageInterShop.png'
import BannerImage from './assets/images/BannerInterShop.png'
import CashbackIcon from '../../assets/icons/Cashback.svg'
import ShoppingBagBlackIcon from '../../assets/icons/ShoppingBagBlack.svg'
import SmartphoneBlackIcon from '../../assets/icons/SmartphoneBlack.svg'
import { AppBar } from '../../components/AppBar'
import { AppContainer } from '../../styles/GlobalStyles'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { SubscriptionsWebview } from '../../types/Subscription'

export const InterShop: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const location = useLocation() as WindowLocation<{
    hasSubscription: boolean
    deeplink: string
    subscriptionData?: SubscriptionsWebview
  }>
  return (
    <>
      <AppBar titleRoute="Frete no Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              Receba cashback do frete no Inter Shop
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Na compra dos produtos com o selo Inter Pass, além de receber parte do valor da compra
              de volta, você também receberá cashback do valor do frete!
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <DeliveryCashback>
            <div>
              <img src={CashbackIcon} alt="Cashback ícone" />
            </div>
            <div>
              <Text color="typography" colorWeight={400} variant="body-3" bold>
                Você receberá até 100% de cashback no frete sendo Inter Pass
              </Text>
            </div>
          </DeliveryCashback>
        </Content>
        <Content>
          <BoxTextAligment style={{ gap: '0.5rem' }}>
            <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
              Como aproveitar:
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Centenas de produtos estão elegíveis para o cashback do frete no Inter Shop. É só
              procurar pelo selo <strong>Inter Pass</strong> pra aproveitar.
            </Text>
          </BoxTextAligment>
          <BannerInterShop src={BannerImage} alt="Banner Inter Shop" />
          <StepsToEnjoyList>
            <StepToEnjoyItem>
              <div>
                <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
                  1.
                </Text>
              </div>
              <div>
                <Text color="typography" colorWeight={400} variant="body-3">
                  Entre no Inter Shop e procure pelos produtos com o selo Inter Pass
                </Text>
              </div>
            </StepToEnjoyItem>
            <StepToEnjoyItem>
              <div>
                <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
                  2.
                </Text>
              </div>
              <div>
                <Text color="typography" colorWeight={400} variant="body-3">
                  Faça sua compra normalmente, e no carrinho, selecione a opção de entrega que tenha
                  o <strong>selo de cashback</strong> no frete
                </Text>
              </div>
            </StepToEnjoyItem>
            <StepToEnjoyItem>
              <div>
                <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
                  3.
                </Text>
              </div>
              <div>
                <Text color="typography" colorWeight={400} variant="body-3" bold>
                  Pronto! É só finalizar a compra, que o valor do cashback do produto e do frete vai
                  cair na sua conta
                </Text>
              </div>
            </StepToEnjoyItem>
          </StepsToEnjoyList>
        </Content>
        <Content>
          <Separator />
        </Content>
        <Content>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Condições
          </Text>
        </Content>
        <Content style={{ marginBottom: '6.5rem' }}>
          <Condition>
            <div>
              <img
                src={ShoppingBagBlackIcon}
                alt="ícone de sacola"
                color="var(--primary500)"
                width="24"
              />
            </div>
            <div>
              <Text color="typography" colorWeight={400} variant="body-3">
                Disponível para produtos selecionados, com o selo Inter Pass
              </Text>
            </div>
          </Condition>

          <Condition>
            <div>
              <img
                src={SmartphoneBlackIcon}
                alt="ícone de smartphone"
                color="var(--primary500)"
                width="24"
              />
            </div>
            <div>
              <Text color="typography" colorWeight={400} variant="body-3">
                Exclusivo para produtos comprados direto no app do Inter
              </Text>
            </div>
          </Condition>
        </Content>
        <AssignBottomButton
          screenName="Inter Shop"
          isBenefit
          deeplink={location.state.deeplink}
          hasSubscription={location.state.hasSubscription}
          disabled={Boolean(location.state?.subscriptionData)}
        />
      </AppContainer>
    </>
  )
}
