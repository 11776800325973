import React from 'react'

import {
  AdvantageBox,
  AdvantageBoxContent,
  AdvantageBoxIcon,
  AdvantageBoxText,
  AdvantageBoxTitle,
} from './styles'

interface Props extends React.HTMLAttributes<unknown> {
  icon: JSX.Element
  title: string
  content: string
}

export const Advantage: React.FC<Props> = ({ icon, title, content, ...props }) => (
  <AdvantageBox {...props}>
    <AdvantageBoxIcon>{icon}</AdvantageBoxIcon>
    <AdvantageBoxContent>
      <AdvantageBoxTitle>{title}</AdvantageBoxTitle>
      <AdvantageBoxText>{content} </AdvantageBoxText>
    </AdvantageBoxContent>
  </AdvantageBox>
)
