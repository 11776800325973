import React, { useCallback } from 'react'

import { interWbSession } from 'inter-webview-bridge'

import { Text } from '@inter/inter-ui'

import ChevronRightIcon from '../../assets/icons/ChevronRight.svg'
import FreteOrangeIcon from '../../assets/icons/FreteOrange.svg'
import CashbackOrangeIcon from '../../assets/icons/CashbackOrange.svg'
import ForkKnifeOrangeIcon from '../../assets/icons/ForkKnifeOrange.svg'
import SmartphoneOrangeIcon from '../../assets/icons/SmartphoneOrange.svg'
import UmbrellaOrangeIcon from '../../assets/icons/UmbrellaOrange.svg'
import InvestmentOrangeIcon from '../../assets/icons/InvestmentOrange.svg'
import CreditCardShieldOrangeIcon from '../../assets/icons/CreditCardShieldOrange.svg'
import { BenefitBox, BenefitBoxContent, BenefitBoxIcon } from './styles'
import { SubscriptionsWebview } from '../../types/Subscription'

export interface Benefit {
  titleLandingPage: string
  description: string
  landingPageLink: string
  benefitName: string
  subscriptionData?: SubscriptionsWebview
}

export const BenefitLP: React.FC<Benefit> = ({
  titleLandingPage,
  description,
  landingPageLink,
  benefitName,
  subscriptionData,
}) => {
  const icons: { [key: string]: string } = {
    cartaoProtegido: CreditCardShieldOrangeIcon,
    investPro: InvestmentOrangeIcon,
    segurosDefault: UmbrellaOrangeIcon,
    interCel: SmartphoneOrangeIcon,
    cashbackTurbinado: CashbackOrangeIcon,
    cashbackFrete: FreteOrangeIcon,
    duoGourmet: ForkKnifeOrangeIcon,
    default: CashbackOrangeIcon,
  }

  const navigateBenefit = useCallback((card_title, screen_name) => {
    interWbSession.requestAnalytics('C_InterX_LP_T_CardResumo', {
      content_card: card_title,
      tela: screen_name,
    })
  }, [])
  return (
    <BenefitBox
      to={landingPageLink}
      state={{ subscriptionData }}
      onClick={() => navigateBenefit(titleLandingPage, landingPageLink)}
    >
      <BenefitBoxIcon>
        <img
          src={icons[benefitName] || icons.default}
          alt="ícone de hamburguer"
          color="var(--primary500)"
          width="24"
        />
      </BenefitBoxIcon>
      <BenefitBoxContent>
        <Text color="typography" colorWeight={500} variant="body-3" bold>
          {titleLandingPage}
        </Text>
        <Text color="typography" colorWeight={400} variant="caption-1">
          {description}
        </Text>
      </BenefitBoxContent>
      <div style={{ display: 'flex', alignSelf: 'center' }}>
        <img
          src={ChevronRightIcon}
          alt="ícone de seta para direita"
          color="var(--primary500)"
          width="30"
        />
      </div>
    </BenefitBox>
  )
}
