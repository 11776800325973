import styled, { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    background-color: var(--white100);
    color: var(--typography400);
  }

  input, button, div, p, h1, h2, h3, h4, h5, h6, span, strong, a {
    font: 400 0.875rem 'Inter', 'Sora', sans-serif;
  }
`

export const AppContainer = styled.div<{
  paddingTopRem?: 0 | 2.5 | 5
}>`
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding-top: ${({ paddingTopRem }) => (paddingTopRem ? `${paddingTopRem}rem` : '0')};

  & > * {
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
  }
`
