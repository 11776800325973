import React from 'react'

import { Text } from '@inter/inter-ui'

import {
  InterCelPlanBoxHeader,
  InterCelPlanBoxFooter,
  InterCelPlanBoxAppsAvailable,
  InterCelPlanBox,
  InterCelPlanBoxPrice,
  InterCelPlanBoxBenefits,
  InterCelPlanBoxDataPlan,
  InterCelPlanBoxCashback,
} from './styles'

interface Props extends React.HTMLAttributes<unknown> {
  internetDataPlan: string
  availableAppsIcons: JSX.Element[]
  benefitPlan: string
  price: string
  cashback: string
  benefitText?: string
}

export const InterCelPlan: React.FC<Props> = ({
  internetDataPlan,
  availableAppsIcons,
  benefitPlan,
  price,
  cashback,
  benefitText,
  ...props
}) => (
  <InterCelPlanBox {...props}>
    <InterCelPlanBoxHeader>
      <InterCelPlanBoxDataPlan>{internetDataPlan}</InterCelPlanBoxDataPlan>

      <InterCelPlanBoxBenefits>
        <Text
          color="typography"
          colorWeight={500}
          variant="caption-2"
          style={{ marginBottom: '0.5rem', color: 'var(--success500)' }}
          bold
        >
          {benefitPlan || '\u00A0'}
        </Text>
        <Text color="typography" colorWeight={400} variant="caption-2">
          {benefitText || 'Apps gratuitos'}
        </Text>
        <InterCelPlanBoxAppsAvailable>
          {availableAppsIcons.map((availableAppIcon) => availableAppIcon)}
        </InterCelPlanBoxAppsAvailable>
      </InterCelPlanBoxBenefits>
    </InterCelPlanBoxHeader>

    <InterCelPlanBoxFooter>
      <InterCelPlanBoxPrice>R$ {price}</InterCelPlanBoxPrice>
      <InterCelPlanBoxCashback>{cashback}</InterCelPlanBoxCashback>
    </InterCelPlanBoxFooter>
  </InterCelPlanBox>
)
