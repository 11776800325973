import styled from 'styled-components'

import { Text } from '@inter/inter-ui'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: var(--white100);
  z-index: 2;
  position: fixed;
`

export const HeaderIconButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
  background: none;
  & > * {
    left: 0;
  }
`

export const HeaderTitle = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.5rem;
`
