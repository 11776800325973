import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.hero-image-content {
    padding: 0;
  }

  &:first-child {
    margin-bottom: 0;
  }
  & > * {
    width: 100%;
  }

  &:last-child {
    padding-top: 1rem;
    border-top: 1px solid var(--gray100);
  }
`

export const BoxTextAligment = styled.div`
  display: grid;
  gap: 1rem;
`
