import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.hero-image-content {
    padding: 0;
  }

  &:first-child {
    margin-bottom: 0;
  }
  & > * {
    width: 100%;
  }

  &:last-child {
    padding-top: 1rem;
    border-top: 1px solid var(--gray100);
  }
`

export const BoxTextAligment = styled.div`
  display: grid;
  gap: 1rem;
`

export const StepsToEnjoyList = styled.div`
  width: 100%;
  margin-top: 1.5rem;
`

export const StepToEnjoyItem = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  & > :first-child {
    margin-right: 0.5rem;
  }
`

export const Separator = styled.hr`
  width: 100%;
  border-style: none none dashed;
  color: var(--gray200);
  margin-bottom: 0.525rem;
`

export const Condition = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  & > :first-child {
    margin-right: 1.5rem;
  }
`

export const DeliveryCashback = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 0.75rem;
    border-radius: 50%;
    background: var(--success100);
  }

  & > :last-child span {
    color: var(--success500);
  }
`

export const BannerInterShop = styled.img`
  margin-top: 1.5rem;
`
