import styled from 'styled-components'

import { Text } from '@inter/inter-ui'
import { Link } from '@reach/router'

export const BenefitBox = styled(Link)`
  width: 100%;
  display: flex;
  border: 0px solid var(--gray100);
  border-bottom-width: 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  text-decoration: none;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    border-width: 0;
  }
`

export const BenefitBoxIcon = styled.div`
  background-color: var(--primary100);
  display: flex;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 4rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
`

export const BenefitBoxContent = styled.div`
  display: grid;
  padding: 0rem 0.875rem;
  gap: 0.5rem;
  width: 100%;
`

export const BackgroundPending = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: var(--alert100);
  border-radius: 0.5rem;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  margin-top: 1rem;

  .alert-text {
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
    flex-direction: column;
  }
`

export const InterPassPlanBox = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  padding: 1rem;
`
export const InterPassPlanBoxHeader = styled.div``
export const InterPassPlanBoxFooter = styled.div``

export const InterPassPlanBoxFooterAssign = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: space-between;
`

export const TrialDaysTag = styled.div`
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;
  background: var(--success100);
  font-size: 10px;
  display: table;
  margin-bottom: 0.5rem;

  &.cashback {
    background: var(--success500);
    & span {
      color: var(--white100);
    }
  }
`

export const CashbackTag = styled.div`
  & span {
    color: var(--success500);
  }
  margin-bottom: 0.5rem;
`

export const InterPassPlanBoxDescription = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-h3',
  semiBold: true,
})``
