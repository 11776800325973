import * as React from 'react'

import { interWbHttp, IWbHttpData, IWbHttpOptions } from 'inter-webview-bridge'

import { ApiErrors } from '../../types/http/ApiError'
import useSafeDispatch from '../useSafeDispatch'
import { isStatus2xx } from '../../utils/httpUtils'

export type THttpMethod = 'get' | 'post' | 'put' | 'delete'

export interface UseAsyncResponse<T> {
  isIdle: boolean
  isPending: boolean
  isError: boolean
  isSuccess: boolean
  status: EAsyncStatus
  data: T
  error?: ApiErrors<T>
  setData: (data: T) => void
  setError: (error: ApiErrors<T>) => void
}

enum EAsyncStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type TState<T = unknown> = {
  status: EAsyncStatus
  data: T
  error?: ApiErrors<T>
}

type TAsyncReducer<T> = React.Reducer<TState<T>, Partial<TState<T>>>

const initialState: TState = {
  /**
   * The data key is explicitly declared to avoid the use of optional chaining
   * every time we access it.
   */
  data: undefined,
  status: EAsyncStatus.IDLE,
}

function reducer(state: TState, update: Partial<TState>): TState {
  return { ...state, ...update }
}

type TAsyncRun<T> = (
  url: string,
  dataToSend?: Partial<T>,
  headers?: Record<string, string>,
) => Promise<T>

/**
 * useAsync hook
 *
 * A hook to perform a asyncronous request.
 *
 * @param httpMethod - The HTTP method of the request
 *
 * @returns
 *
 * The "run" function, the resolved value, the request status, and some
 * utilities like "isSuccess", "isError" "setData", "setError", etc.
 *
 * @example
 *
 * import { useAsync } from './hooks'
 *
 * const Products = () => {
 *   const getProducts = useAsync('get')
 *
 *   React.useEffect(() => {
 *     getProducts.run(`api.com/product`)
 *   }, [getProducts.run])
 *
 *   if (getProducts.isSuccess) {
 *     return <>{produts.data.map(product => <div>Product {product.name}</div>)}</>
 *   }
 *   if (getProducts.isPending) { return <Loading /> }
 *   if (getProducts.isError) { return <Error /> }
 *   return null
 * }
 */
const useAsync = <T>(
  httpMethod: THttpMethod,
  options?: IWbHttpOptions,
): [UseAsyncResponse<T>, TAsyncRun<T>] => {
  const [{ data, status, error }, unsafeSetState] = React.useReducer<TAsyncReducer<T>>(
    reducer as TAsyncReducer<T>,
    initialState as TState<T>,
  )
  const setState = useSafeDispatch(unsafeSetState)

  const setData = React.useCallback(
    (state: T) => setState({ data: state, status: EAsyncStatus.SUCCESS }),
    [setState],
  )

  const setError = React.useCallback(
    (err: ApiErrors<T>): void => setState({ error: err, status: EAsyncStatus.ERROR }),
    [setState],
  )

  const run = React.useCallback(
    async <A>(url: string, dataToSend?: Partial<T | A>, headers?: Record<string, string>) => {
      setState({ status: EAsyncStatus.PENDING })
      try {
        let responseHttp = {} as IWbHttpData
        if (httpMethod === 'get' || httpMethod === 'delete') {
          responseHttp = await interWbHttp[httpMethod](url, {
            ...headers,
          })
        } else {
          responseHttp = await interWbHttp[httpMethod](url, dataToSend, { ...headers }, options)
        }

        if (isStatus2xx(responseHttp.httpStatus)) {
          const formatedResponse = JSON.parse(responseHttp.response)
          // console.log('responseFormated', formatedResponse)
          setData(formatedResponse)
          return formatedResponse
        }

        setError(new ApiErrors({ message: 'fail' }))
        return Promise.reject(new ApiErrors({ message: 'fail' }))
      } catch (err) {
        // const formatedError = handleError(err as unknown)
        setError(new ApiErrors({ message: 'fail' }))

        // console.log('ERROR', err)
        return Promise.reject(err)
      }
    },

    [setState, httpMethod, setError, options, setData],
  )

  return [
    {
      isIdle: status === EAsyncStatus.IDLE,
      isPending: status === EAsyncStatus.PENDING,
      isError: status === EAsyncStatus.ERROR,
      isSuccess: status === EAsyncStatus.SUCCESS,
      status: status as EAsyncStatus,
      data,
      error,
      setData,
      setError,
    },
    run,
  ]
}

export default useAsync
