import React, { useCallback, useEffect, useState } from 'react'

import queryString from 'query-string'

import { Button, Text } from '@inter/inter-ui'
import { navigate, RouteComponentProps } from '@reach/router'

import { BoxTextAligment, Content, Separator, SignedPlan } from './styles'
import HeroImage from './assets/images/HeroCompleteSigned.png'
import { AppContainer } from '../../styles/GlobalStyles'
import { AppBar } from '../../components/AppBar'
import { bridgeNative } from '../../App'

type EndingResponseDetails = {
  additionalFields: {
    price: number
    planName: string
  }
}

export const CompleteSigned: React.FC<RouteComponentProps> = () => {
  const [endingResponse, setEndingResponse] = useState<EndingResponseDetails>()

  const subscriptionId = queryString.parse(window.location.search)

  useEffect(() => {
    async function endingPageInfos() {
      try {
        const endingPageResponse: EndingResponseDetails = await bridgeNative.execute({
          action: 'requestSubscriptionAccessInfo',
          metadata: {
            productId: 'INTER_PRIME',
            subscriptionId: subscriptionId.subscriptionId,
          },
        })

        setEndingResponse(endingPageResponse)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`error: ${error}`)
      }
    }

    endingPageInfos()
  }, [subscriptionId.subscriptionId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sendToHome = useCallback(() => {
    navigate('/')
  }, [])

  return (
    <>
      <AppBar titleRoute="Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              Agora você é Inter Pass!
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Você já pode aproveitar todos os benefícios exclusivos de sua assinatura.
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <Separator />
        </Content>
        <Content style={{ marginBottom: '6rem' }}>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Plano contratado:
          </Text>
          <SignedPlan>
            <Text color="typography" colorWeight={400} variant="caption-1">
              {endingResponse?.additionalFields.planName}
            </Text>
            <Text color="typography" colorWeight={500} variant="caption-1" bold>
              R$ {endingResponse?.additionalFields.price.toFixed(2)}
            </Text>
          </SignedPlan>
        </Content>
        <Content
          style={{
            position: 'fixed',
            bottom: 0,
            backgroundColor: 'var(--white100)',
            marginBottom: 0,
            paddingBottom: '1rem',
            paddingTop: '0.5rem',
            borderWidth: 0,
          }}
        >
          <Button style={{ width: '100%' }} onClick={() => sendToHome()}>
            Ir para a home do Inter Pass
          </Button>
        </Content>
      </AppContainer>
    </>
  )
}
