import React, { useEffect } from 'react'

import { Text } from '@inter/inter-ui'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'

import {
  BoxTextAligment,
  Content,
  DeliveryCashback,
  SecurityBox,
  SecurityBoxIcon,
  SecurityBoxHeader,
} from './styles'
import HeroImage from './assets/images/HeroImageSeguros.png'
import CashbackIcon from '../../assets/icons/Cashback.svg'
import HealthOrangeIcon from '../../assets/icons/HealthOrange.svg'
import HouseFinancialOrangeIcon from '../../assets/icons/HouseFinancialOrange.svg'
import TeethOrangeIcon from '../../assets/icons/TeethOrange.svg'
import PadlockOrangeIcon from '../../assets/icons/PadlockOrange.svg'
import UmbrellaOrangeIcon from '../../assets/icons/UmbrellaOrange.svg'
import { AppBar } from '../../components/AppBar'
import { AppContainer } from '../../styles/GlobalStyles'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { SubscriptionsWebview } from '../../types/Subscription'

export const Seguros: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const location = useLocation() as WindowLocation<{
    hasSubscription: boolean
    deeplink: string
    subscriptionData?: SubscriptionsWebview
  }>
  return (
    <>
      <AppBar titleRoute="Seguros no Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              20% de cashback na contratação de seguros
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Fique protegido com o seguro certo pra você! Válido para Seguro Cartão + Protegido,
              Seguro de Vida, Residencial, Odonto e Proteção Financeira.
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <DeliveryCashback>
            <div>
              <img src={CashbackIcon} alt="Cashback ícone" />
            </div>
            <div>
              <Text color="typography" colorWeight={400} variant="body-3" bold>
                Você recebe 20% de cashback na contratação sendo Inter Pass
              </Text>
            </div>
          </DeliveryCashback>
        </Content>
        <Content style={{ marginBottom: '6.5rem' }}>
          <div>
            <SecurityBox>
              <SecurityBoxHeader>
                <SecurityBoxIcon>
                  <img
                    src={UmbrellaOrangeIcon}
                    alt="ícone de guarda-chuva"
                    color="var(--primary500)"
                    width="24"
                  />
                </SecurityBoxIcon>
                <div>
                  <div>
                    <Text color="typography" colorWeight={500} variant="body-3" bold>
                      Cartão + Protegido
                    </Text>
                  </div>
                  <div>
                    <Text color="typography" colorWeight={400} variant="caption-1" bold>
                      A partir de R$ 2,90 / mês
                    </Text>
                  </div>
                </div>
              </SecurityBoxHeader>
              <Text color="typography" colorWeight={500} variant="caption-1">
                Sua melhor escolha para uma fatura sem surpresas.
              </Text>
            </SecurityBox>
            <SecurityBox>
              <SecurityBoxHeader>
                <SecurityBoxIcon>
                  <img
                    src={HealthOrangeIcon}
                    alt="ícone de saúde"
                    color="var(--primary500)"
                    width="24"
                  />
                </SecurityBoxIcon>
                <div>
                  <div>
                    <Text color="typography" colorWeight={500} variant="body-3" bold>
                      Seguro de Vida
                    </Text>
                  </div>
                  <div>
                    <Text color="typography" colorWeight={400} variant="caption-1" bold>
                      A partir de R$ 13,00 /mês
                    </Text>
                  </div>
                </div>
              </SecurityBoxHeader>
              <Text color="typography" colorWeight={500} variant="caption-1">
                Um seguro de vida pra você cuidar da sua família no futuro, e também pra viver
                melhor hoje.
              </Text>
            </SecurityBox>
            <SecurityBox>
              <SecurityBoxHeader>
                <SecurityBoxIcon>
                  <img
                    src={HouseFinancialOrangeIcon}
                    alt="ícone de seguro residencial"
                    color="var(--primary500)"
                    width="24"
                  />
                </SecurityBoxIcon>
                <div>
                  <div>
                    <Text color="typography" colorWeight={500} variant="body-3" bold>
                      Seguro residencial
                    </Text>
                  </div>
                  <div>
                    <Text color="typography" colorWeight={400} variant="caption-1" bold>
                      Sob consulta
                    </Text>
                  </div>
                </div>
              </SecurityBoxHeader>
              <Text color="typography" colorWeight={500} variant="caption-1">
                Garanta o aluguel mesmo nos meses mais apertados e proteja seu patrimônio.
              </Text>
            </SecurityBox>

            <SecurityBox>
              <SecurityBoxHeader>
                <SecurityBoxIcon>
                  <img
                    src={TeethOrangeIcon}
                    alt="ícone de dente"
                    color="var(--primary500)"
                    width="24"
                  />
                </SecurityBoxIcon>
                <div>
                  <div>
                    <Text color="typography" colorWeight={500} variant="body-3" bold>
                      Seguro odonto
                    </Text>
                  </div>
                  <div>
                    <Text color="typography" colorWeight={400} variant="caption-1" bold>
                      A partir de R$ 37,90 /mês
                    </Text>
                  </div>
                </div>
              </SecurityBoxHeader>
              <Text color="typography" colorWeight={500} variant="caption-1">
                Mais de 45 mil locais de atendimento no país com a qualidade da MetLife.
              </Text>
            </SecurityBox>

            <SecurityBox>
              <SecurityBoxHeader>
                <SecurityBoxIcon>
                  <img
                    src={PadlockOrangeIcon}
                    alt="ícone de cadeado"
                    color="var(--primary500)"
                    width="24"
                  />
                </SecurityBoxIcon>
                <div>
                  <div>
                    <Text color="typography" colorWeight={500} variant="body-3" bold>
                      Proteção financeira
                    </Text>
                  </div>
                  <div>
                    <Text color="typography" colorWeight={400} variant="caption-1" bold>
                      Por R$ 48,00 /mês
                    </Text>
                  </div>
                </div>
              </SecurityBoxHeader>
              <Text color="typography" colorWeight={500} variant="caption-1">
                Garanta o pagamento do seu cartão mesmo nos meses mais apertados.
              </Text>
            </SecurityBox>
          </div>
        </Content>
        <AssignBottomButton
          screenName="Seguros"
          isBenefit
          deeplink={location.state.deeplink}
          hasSubscription={location.state.hasSubscription}
          disabled={Boolean(location.state?.subscriptionData)}
        />
      </AppContainer>
    </>
  )
}
