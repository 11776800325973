import React, { useCallback, useEffect } from 'react'

import { interWbSession } from 'inter-webview-bridge'
// import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Babi, Button, Icon, Text } from '@inter/inter-ui'
import { navigate, RouteComponentProps } from '@reach/router'

import { Content, Separator, BottomButtonLink, BackgroundPending } from './styles'
import ChevronRightIcon from '../../assets/icons/ChevronRight.svg'
import WalletBlackIcon from '../../assets/icons/WalletBlack.svg'
// import Carousel1 from './assets/carousel-1.png'
// import Carousel2 from './assets/carousel-2.png'
// import Carousel3 from './assets/carousel-3.png'
import CashbackBlackIcon from '../../assets/icons/CashbackBlack.svg'
import useAsync from '../../hooks/useAsync'
import { Landing } from '../../types/LandingPage'
import HomeSkeleton from '../HomeSkeleton'
import { Error } from '../Error'
import { AppContainer } from '../../styles/GlobalStyles'
import { AppBar } from '../../components/AppBar'
import { bridgeNative } from '../../App'
import { BenefitHome } from '../../components/BenefitHome'

type SubscriptionsWebview = {
  subscriptionId: string
  planId: string
  status: string
  statusTitle: string
  statusText: string
  additionalFields: {
    providerUrl: string
    shareUrl: string
  }
}
interface HomeSignedProps extends RouteComponentProps {
  subscriptionData?: SubscriptionsWebview
  termsOfUse?: string
}

type CancelDataPage = {
  imageUrl: string
  title: string
  subtitle: string
  cancellation: Cancellation
}

type Cancellation = {
  title: string
  subtitle: string
  reasons: Reason[]
}
export interface Reason {
  id: string
  iconUrl: string
  text: string
}

export const HomeSigned: React.FC<HomeSignedProps> = ({ subscriptionData, termsOfUse }) => {
  const [dataLandingPage, getDataLandingPage] = useAsync<Landing>('get')
  const [cancelDataPage, getCancelDataPage] = useAsync<CancelDataPage>('get')

  // const settings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1.2,
  //   slidesToScroll: 1,
  // }
  const fetchLandingPage = useCallback(async () => {
    await getDataLandingPage(`app-pf/interprime/v1/home`, undefined, {
      'x-inter-prime-status': 'true',
    })
  }, [getDataLandingPage])

  const fetchCancelDataPage = useCallback(async () => {
    if (subscriptionData?.subscriptionId) {
      await getCancelDataPage(
        `app-pf/subscription/v1/subscriptions/${subscriptionData.subscriptionId}/cancel`,
      )
    }
  }, [getCancelDataPage, subscriptionData?.subscriptionId])

  useEffect(() => {
    fetchLandingPage()
    fetchCancelDataPage()
  }, [fetchLandingPage, fetchCancelDataPage])

  const handleTermsOfUse = useCallback(() => {
    bridgeNative.execute({
      action: 'sendToTermsSubscription',
      metadata: {
        pdfUrl: termsOfUse,
      },
    })
  }, [termsOfUse])

  useEffect(() => {
    window.scrollTo(0, 0)
    interWbSession.requestAnalytics('C_InterX_HomeLP_DC_Inicio', {
      tela: 'home',
    })
  }, [])

  const openDeepLinkPage = useCallback((deeplink, benefitName?) => {
    if (benefitName) {
      interWbSession.requestAnalytics('C_InterX_Home_T_VerBeneficio', {
        content_card: benefitName,
      })
    }

    bridgeNative.execute({
      action: 'openDeeplink',
      metadata: {
        url: deeplink,
      },
    })
  }, [])

  // const bannerPromo = useCallback(
  //   (banner_promo_name, deeplink) => {
  //     interWbSession.requestAnalytics('C_InterX_Home_T_BannerPromo', {
  //       content_banner: banner_promo_name,
  //     })
  //     openDeepLinkPage(deeplink)
  //   },
  //   [openDeepLinkPage],
  // )

  const cashbackStatement = useCallback(() => {
    interWbSession.requestAnalytics('C_InterX_Home_T_ExtratoCashback')
    openDeepLinkPage('bancointer://cashback')
  }, [openDeepLinkPage])

  const changePaymentMethod = useCallback(() => {
    interWbSession.requestAnalytics('C_InterX_Home_T_MudarPag')
    bridgeNative.execute({
      action: 'sendToPaymentSubscription',
      metadata: {
        subscriptionId: subscriptionData?.subscriptionId,
        productId: 'INTER_PRIME',
      },
    })
  }, [subscriptionData?.subscriptionId])

  const cancelInterPass = useCallback(() => {
    interWbSession.requestAnalytics('C_InterX_Home_T_Cancelar')
    navigate(`/cancelamento/${subscriptionData?.subscriptionId}`)
  }, [subscriptionData?.subscriptionId])

  const openBabi = useCallback(() => {
    interWbSession.requestAnalytics('C_InterX_Home_T_Babi')
    bridgeNative.execute({ action: 'sendToBabi' })
  }, [])

  if (dataLandingPage.isError) {
    return <Error />
  }

  if (dataLandingPage.isPending) {
    return <HomeSkeleton />
  }
  if (dataLandingPage.isSuccess) {
    return (
      <>
        <AppBar titleRoute="Inter Pass" goBackNative />
        <AppContainer paddingTopRem={5}>
          <Content>
            <Text
              style={{ marginBottom: '1rem' }}
              color="typography"
              colorWeight={500}
              variant="headline-h2"
              semiBold
            >
              Resumo
            </Text>
            <div style={{ display: 'grid', gap: '0.5rem' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Serviço assinado
                </Text>
                <Text color="typography" colorWeight={500} variant="caption-1" bold>
                  {dataLandingPage.data?.sections.find((c) => c.sectionType === 'PRIME_SUMMARY')
                    ?.summary?.signedService || '-'}
                </Text>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Valor do plano
                </Text>
                <Text color="typography" colorWeight={500} variant="caption-1" bold>
                  {dataLandingPage.data?.sections.find((c) => c.sectionType === 'PRIME_SUMMARY')
                    ?.summary?.planValue || '-'}
                </Text>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  {subscriptionData?.status === 'CANCELED' ? 'Expira em' : 'Renovação automática'}
                </Text>
                <Text color="typography" colorWeight={500} variant="caption-1" bold>
                  {subscriptionData?.status === 'CANCELED' && cancelDataPage.isSuccess
                    ? cancelDataPage.data.subtitle
                        .match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g)
                        ?.find((e) => e)
                    : dataLandingPage.data?.sections.find((c) => c.sectionType === 'PRIME_SUMMARY')
                        ?.summary?.renewalDate || '-'}
                </Text>
              </div>
            </div>
          </Content>
          <Separator />
          {/* <Carousel>
            <Slider
              {...settings}
              centerMode
              centerPadding="24px"
              nextArrow={<></>}
              prevArrow={<></>}
            >
              <button
                type="button"
                onClick={() =>
                  bannerPromo(
                    'PASS_BANNER_GERAL',
                    'bancointer://marketplace/produtos?colecaoId=10122748',
                  )
                }
              >
                <img src={Carousel1} alt="imagem do Carrossel" />
              </button>
              <button
                type="button"
                onClick={() =>
                  bannerPromo(
                    'PASS_BANNER_ELETRO',
                    'bancointer://marketplace/produtos?colecaoId=17222753',
                  )
                }
              >
                <img src={Carousel2} alt="imagem do Carrossel" />
              </button>
              <button
                type="button"
                onClick={() =>
                  bannerPromo(
                    'PASS_BANNER_TECH',
                    'bancointer://marketplace/produtos?colecaoId=18622754',
                  )
                }
              >
                <img src={Carousel3} alt="imagem do Carrossel" />
              </button>
            </Slider>
          </Carousel> */}
          <Content>
            <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
              Seus benefícios Inter Pass
            </Text>
            {(subscriptionData?.status === 'CANCELED' ||
              subscriptionData?.status === 'PENDING_PAYMENT') && (
              <BackgroundPending>
                <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
                  <Icon name="warning" size={16} color="var(--error500)" />
                </div>
                <div>
                  <div className="alert-text">
                    <Text color="typography" colorWeight={500} variant="body-3" bold>
                      {subscriptionData?.statusTitle}
                    </Text>
                    <Text color="typography" colorWeight={500} variant="caption-1">
                      {subscriptionData?.statusText}
                    </Text>
                  </div>
                </div>
              </BackgroundPending>
            )}
            <div style={{ width: '100%' }}>
              {dataLandingPage.data?.sections
                .find((c) => c.sectionType === 'PRIME_BENEFITS')
                ?.benefits?.map((props) => (
                  <BenefitHome {...props} />
                ))}
            </div>
          </Content>
          <Separator />
          <Content>
            <div style={{ width: '100%' }}>
              <BottomButtonLink onClick={cashbackStatement}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={CashbackBlackIcon}
                    alt="ícone de cashback"
                    color="var(--primary500)"
                    width="24"
                    style={{ marginRight: '1rem' }}
                  />
                  <Text color="typography" colorWeight={500} variant="caption-1" bold>
                    Extrato de cashback
                  </Text>
                </div>

                <img
                  src={ChevronRightIcon}
                  alt="ícone de seta para direita"
                  color="var(--primary500)"
                  width="24"
                />
              </BottomButtonLink>
              <BottomButtonLink onClick={changePaymentMethod}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={WalletBlackIcon}
                    alt="ícone de carteira"
                    color="var(--primary500)"
                    width="24"
                    style={{ marginRight: '1rem' }}
                  />
                  <Text color="typography" colorWeight={500} variant="caption-1" bold>
                    Pagamento
                  </Text>
                </div>

                <img
                  src={ChevronRightIcon}
                  alt="ícone de seta para direita"
                  color="var(--primary500)"
                  width="24"
                />
              </BottomButtonLink>
              {subscriptionData?.status !== 'CANCELED' && (
                <BottomButtonLink onClick={cancelInterPass}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon
                      name="canceled"
                      size={24}
                      color="var(--gray500)"
                      style={{ marginRight: '1rem' }}
                    />
                    <Text color="typography" colorWeight={500} variant="caption-1" bold>
                      Cancelamento
                    </Text>
                  </div>

                  <img
                    src={ChevronRightIcon}
                    alt="ícone de seta para direita"
                    color="var(--primary500)"
                    width="24"
                  />
                </BottomButtonLink>
              )}
            </div>
          </Content>
          <Content>
            <Button
              style={{
                display: 'flex',
                alignSelf: 'flex-start',
                padding: 0,
                marginBottom: '1.5rem',
                width: 'auto',
              }}
              variant="link"
              onClick={handleTermsOfUse}
            >
              <Text color="primary" colorWeight={500} variant="caption-1" bold>
                Ver Termos e Condições
              </Text>
            </Button>
            <Babi onClick={openBabi} />
          </Content>
        </AppContainer>
      </>
    )
  }
  return null
}
