import React, { useEffect } from 'react'

import { Text } from '@inter/inter-ui'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'

import { BoxTextAligment, Content, PlansCardList } from './styles'
import HeroImage from './assets/images/HeroImageInterCel.png'
import PortabilityBlackIcon from '../../assets/icons/PortabilityBlack.svg'
import ProductBlackIcon from '../../assets/icons/ProductBlack.svg'
import CashbackBlackIcon from '../../assets/icons/CashbackBlack.svg'
import WhatsappAppIcon from './assets/icons/WhatsappAppIcon.svg'
import InterAppIcon from './assets/icons/InterAppIcon.svg'
import WazeAppIcon from './assets/icons/WazeAppIcon.svg'
import MoovitAppIcon from './assets/icons/MoovitAppIcon.png'
import InstagramAppIcon from './assets/icons/InstagramAppIcon.svg'
import TiktokAppIcon from './assets/icons/TiktokAppIcon.svg'
import FacebookAppIcon from './assets/icons/FacebookAppIcon.svg'
import Mais4AppIcon from './assets/icons/Mais4Icon.svg'
import { AppBar } from '../../components/AppBar'
import { AppContainer } from '../../styles/GlobalStyles'
import { Advantage } from '../../components/Advantage'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { InterCelPlan } from '../../components/InterCelPlan'
import { SubscriptionsWebview } from '../../types/Subscription'

export const InterCel: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const whatsappAppIconImg = <img src={WhatsappAppIcon} alt="Whatsapp ícone" />
  const interAppIconImg = <img src={InterAppIcon} alt="Inter ícone" />
  const wazeAppIconImg = <img src={WazeAppIcon} alt="Waze ícone" />
  const moovitAppIconImg = <img src={MoovitAppIcon} alt="Moovit ícone" />
  const instagramAppIconImg = <img src={InstagramAppIcon} alt="Instagram ícone" />
  const tiktokAppIconImg = <img src={TiktokAppIcon} alt="TikTok ícone" />
  const facebookAppIconImg = <img src={FacebookAppIcon} alt="Facebook ícone" />
  const mais4AppIconImg = <img src={Mais4AppIcon} alt="Mais 4 ícone" />

  const interCelPlans = [
    {
      internetDataPlan: '10GB',
      availableAppsIcons: [
        instagramAppIconImg,
        tiktokAppIconImg,
        facebookAppIconImg,
        mais4AppIconImg,
      ],
      benefitPlan: '6GB + 4GB redes sociais',
      price: '45,00 / mês',
      cashback: '4% + 7% de cashback',
      benefitText: 'Apps inclusos',
    },
    {
      internetDataPlan: '13GB',
      availableAppsIcons: [moovitAppIconImg, wazeAppIconImg, whatsappAppIconImg, interAppIconImg],
      benefitPlan: '',
      price: '75,00 / mês',
      cashback: '4% + 7% de cashback',
    },
    {
      internetDataPlan: '8GB',
      availableAppsIcons: [moovitAppIconImg, wazeAppIconImg, whatsappAppIconImg, interAppIconImg],
      benefitPlan: '',
      price: '50,00 /mês',
      cashback: '4% + 7% de cashback',
    },
    {
      internetDataPlan: '6GB',
      availableAppsIcons: [moovitAppIconImg, wazeAppIconImg, whatsappAppIconImg, interAppIconImg],
      benefitPlan: '',
      price: '40,00 /mês',
      cashback: '4% + 7% de cashback',
    },
    {
      internetDataPlan: '4GB',
      availableAppsIcons: [moovitAppIconImg, wazeAppIconImg, whatsappAppIconImg, interAppIconImg],
      benefitPlan: '',
      price: '30,00 /mês',
      cashback: '4% + 7% de cashback',
    },
  ]

  const location = useLocation() as WindowLocation<{
    hasSubscription: boolean
    deeplink: string
    subscriptionData?: SubscriptionsWebview
  }>
  return (
    <>
      <AppBar titleRoute="Inter Cel no Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content
          className="hero-image-content"
          style={{ marginTop: '1rem', marginBottom: '1.5rem' }}
        >
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              7% de cashback a mais nos planos da Inter Cel
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Receba <strong style={{ fontWeight: 'bold' }}>até 5GB de bônus por 12 meses</strong>{' '}
              fazendo a portabilidade de qualquer operadora (exceto Vivo e suas operadoras móveis
              virtuais).
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Conheça os planos
          </Text>
        </Content>
        <PlansCardList>
          {interCelPlans.map((interCelPlanProps) => (
            <InterCelPlan {...interCelPlanProps} />
          ))}
        </PlansCardList>
        <Content style={{ marginBottom: '6.5rem' }}>
          <Text
            color="typography"
            colorWeight={500}
            variant="headline-h3"
            semiBold
            style={{ marginBottom: '0.5rem' }}
          >
            Suas vantagens na Inter Cel:
          </Text>

          <Advantage
            icon={
              <img
                src={PortabilityBlackIcon}
                alt="ícone de portabilidade"
                color="var(--primary500)"
                width="24"
              />
            }
            title="Traga seu número e ganhe bônus"
            content="Você continua com seu número atual e ainda recebe mais internet."
          />
          <Advantage
            icon={
              <img
                src={CashbackBlackIcon}
                alt="ícone de cashback"
                color="var(--primary500)"
                width="24"
              />
            }
            title="Cashback na sua conta"
            content="Além de internet sem cortes e ligações para qualquer número, você também tem
            cashback nas recargas."
          />
          <Advantage
            icon={
              <img
                src={ProductBlackIcon}
                alt="ícone de produto"
                color="var(--primary500)"
                width="24"
              />
            }
            title="Receba seu chip em casa"
            content="Escolha seu plano e receba o chip na sua casa."
          />
        </Content>
        <AssignBottomButton
          screenName="Inter Cel"
          isBenefit
          deeplink={location.state.deeplink}
          hasSubscription={location.state.hasSubscription}
          disabled={Boolean(location.state?.subscriptionData)}
        />
      </AppContainer>
    </>
  )
}
