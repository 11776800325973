import React, { useCallback } from 'react'

import { interWbSession } from 'inter-webview-bridge'

import { Text } from '@inter/inter-ui'

import {
  InterPassPlanBox,
  InterPassPlanBoxHeader,
  InterPassPlanBoxDescription,
  InterPassPlanBoxFooter,
  InterPassPlanBoxFooterAssign,
  TrialDaysTag,
  CashbackTag,
} from './styles'
import { bridgeNative } from '../../App'
import ChevronRightIcon from '../../assets/icons/ChevronRight.svg'

interface Props extends React.HTMLAttributes<unknown> {
  name: string
  trialPeriodDays: string
  price: string
  description: string
  cashback: string
}

export const InterPassPlan: React.FC<Props> = ({
  name,
  trialPeriodDays,
  description,
  cashback,
  price,
  ...props
}) => {
  const assignCardPlan = useCallback((card_plan) => {
    interWbSession.requestAnalytics('C_InterX_LP_T_CardPlano', {
      content_card: card_plan,
    })
    bridgeNative.execute({
      action: 'sendToSubscription',
      metadata: { productId: 'INTER_PRIME' },
    })
  }, [])

  const formatValuePrice = (defaultPrice: string | number) => {
    const floorDecimal = Math.floor(Number(defaultPrice) * 100) / 100 || 0
    return Number(floorDecimal.toFixed(2)).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  return (
    <InterPassPlanBox onClick={() => assignCardPlan(name)} {...props}>
      <InterPassPlanBoxHeader>
        {Number(trialPeriodDays) > 0 ? (
          <TrialDaysTag>
            <Text color="typography" colorWeight={500} variant="caption-2" bold>
              Teste {trialPeriodDays} dia{Number(trialPeriodDays) > 1 && 's'} grátis
            </Text>
          </TrialDaysTag>
        ) : (
          <br />
        )}
        <InterPassPlanBoxDescription>{description}</InterPassPlanBoxDescription>
      </InterPassPlanBoxHeader>
      <InterPassPlanBoxFooter>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '0.75rem',
            marginBottom: '0.5rem',
          }}
        >
          <Text color="typography" colorWeight={400} variant="caption-2">
            Valor do plano:
          </Text>
          <Text color="typography" colorWeight={400} variant="caption-2">
            {formatValuePrice(price)}
          </Text>
          <CashbackTag>
            {Number(cashback) > 0 ? (
              <Text color="typography" colorWeight={500} variant="caption-2" bold>
                {formatValuePrice(cashback)} de cashback
              </Text>
            ) : (
              <Text color="typography" colorWeight={500} variant="caption-2" bold>
                &nbsp;
              </Text>
            )}
          </CashbackTag>
        </div>
        <hr
          style={{
            borderStyle: 'none none dashed',
            color: 'var(--gray200)',
            marginBottom: '0.525rem',
          }}
        />
        <InterPassPlanBoxFooterAssign>
          <Text color="primary" colorWeight={500} variant="caption-1" bold>
            Assinar
          </Text>
          <img
            src={ChevronRightIcon}
            alt="ícone de seta para direita"
            color="var(--primary500)"
            width="16"
          />
        </InterPassPlanBoxFooterAssign>
      </InterPassPlanBoxFooter>
    </InterPassPlanBox>
  )
}
