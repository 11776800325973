import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.hero-image-content {
    padding: 0;
  }

  & > * {
    width: 100%;
  }

  &:last-child {
    padding-top: 1rem;
    border-top: 1px solid var(--gray100);
  }
`

export const BenefitCard = styled.div`
  width: 100%;
  background-color: var(--gray100);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 1rem;
`

export const BenefitCardHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
`

export const BenefitCardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`

export const BenefitCardContent = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

export const Separator = styled.hr`
  width: 100%;
  color: var(--gray400);
  border-width: 0;
  border-bottom-width: 0.5rem;
  margin: 0.5rem 0 1.5rem;
`

// export const Carousel = styled.div`
//   display: flex;
//   width: 100vw;
//   overflow: auto;
//   padding-left: 1.5rem;
//   margin-bottom: 1.5rem;

//   & button {
//     width: 100%;
//     min-width: 19rem;
//     margin-right: 0.75rem;
//     background: none;

//     & img {
//       width: 100%;
//       height: auto;
//     }
//   }
// `

export const Carousel = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  .slick-initialized .slick-slide {
    padding: 0 0rem;
  }

  .slick-dots li.slick-active button::before {
    color: var(--orange500);
  }

  & button {
    background: none;
  }

  & img {
    width: 100%;
    padding-right: 1rem;
  }
`

export const BottomButtonLink = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0;
  border-bottom: 1px solid var(--gray200);
  background: none;
  width: 100%;
`

export const BackgroundPending = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: var(--error100);
  border-radius: 0.5rem;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  margin-top: 1rem;

  .alert-text {
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
    flex-direction: column;
  }
`
