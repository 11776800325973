import React, { useEffect } from 'react'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Icon, Text } from '@inter/inter-ui'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'

import { BoxTextAligment, Content, DeliveryCashback, Carousel } from './styles'
import HeroImage from './assets/images/HeroImageDuoGourmet.png'
import CashbackIcon from '../../assets/icons/Cashback.svg'
import HamburguerBlackIcon from '../../assets/icons/HamburguerBlack.svg'
import HeartBlackIcon from '../../assets/icons/HeartBlack.svg'
import MarketBlackIcon from '../../assets/icons/MarketBlack.svg'
import Carousel1 from './assets/carousel-1.png'
import Carousel2 from './assets/carousel-2.png'
import Carousel3 from './assets/carousel-3.png'
import Carousel4 from './assets/carousel-4.png'
import { AppBar } from '../../components/AppBar'
import { AppContainer } from '../../styles/GlobalStyles'
import { Advantage } from '../../components/Advantage'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { SubscriptionsWebview } from '../../types/Subscription'

export const DuoGourmet: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.25,
    slidesToScroll: 1,
  }

  const location = useLocation() as WindowLocation<{
    hasSubscription: boolean
    deeplink: string
    subscriptionData?: SubscriptionsWebview
  }>

  return (
    <>
      <AppBar titleRoute="Duo Gourmet no Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              10% de cashback no Inter Duo Gourmet Anual
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Com o Inter Duo Gourmet, você conhece o melhor da gastronomia e garante desconto no
              valor da sua conta. Sendo Inter Pass você ganha{' '}
              <strong style={{ fontWeight: 'bold' }}>10% de cashback</strong> na assinatura anual.
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <DeliveryCashback>
            <div>
              <img src={CashbackIcon} alt="Cashback ícone" />
            </div>
            <div>
              <Text color="typography" colorWeight={400} variant="body-3" bold>
                Ganhe até R$ 45,00 de cashback na assinatura anual sendo Inter Pass
              </Text>
            </div>
          </DeliveryCashback>
        </Content>
        <Content>
          <BoxTextAligment style={{ gap: '0.5rem' }}>
            <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
              Economize nos melhores restaurantes do Brasil
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Encontre os melhores restaurantes e ganhe até 100% de desconto no segundo prato. Use
              quando quiser, em várias cidades.
            </Text>
          </BoxTextAligment>
        </Content>
        {/* <Content style={{ marginBottom: '1rem' }}>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Aproveite o melhor da gastronomia
          </Text>
        </Content> */}
        {/* <Carousel>
          <div>
            <img src={Carousel1} alt="imagem do Carrossel" />
          </div>
          <div>
            <img src={Carousel2} alt="imagem do Carrossel" />
          </div>
          <div>
            <img src={Carousel3} alt="imagem do Carrossel" />
          </div>
          <div>
            <img src={Carousel4} alt="imagem do Carrossel" />
          </div>
        </Carousel> */}
        <Content style={{ marginBottom: '1rem' }}>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Aproveite o melhor da gastronomia
          </Text>
        </Content>
        <Carousel>
          <Slider {...settings} centerMode centerPadding="24px" nextArrow={<></>} prevArrow={<></>}>
            <div>
              <img src={Carousel1} alt="imagem do Carrossel" />
            </div>
            <div>
              <img src={Carousel2} alt="imagem do Carrossel" />
            </div>
            <div>
              <img src={Carousel3} alt="imagem do Carrossel" />
            </div>
            <div>
              <img src={Carousel4} alt="imagem do Carrossel" />
            </div>
          </Slider>
        </Carousel>
        <Content style={{ marginBottom: '7rem' }}>
          <Text
            color="typography"
            colorWeight={500}
            variant="headline-h3"
            semiBold
            style={{ marginBottom: '0.5rem' }}
          >
            Vantagens do Duo Gourmet
          </Text>
          <Advantage
            icon={
              <img
                src={HamburguerBlackIcon}
                alt="ícone de hamburguer"
                color="var(--primary500)"
                width="24"
              />
            }
            title="Coma bem pagando menos"
            content="Quem assina o Inter Duo Gourmet pede um prato principal e ganha outro de igual ou menor valor."
          />
          <Advantage
            icon={
              <img
                src={HeartBlackIcon}
                alt="ícone de coração"
                color="var(--primary500)"
                width="24"
              />
            }
            title="Fácil de usar e economizar"
            content="Ao pedir a conta é só apresentar o código gerado no app ao garçom, e você pode
            usar quantas vezes quiser."
          />
          <Advantage
            icon={
              <img src={MarketBlackIcon} alt="ícone de loja" color="var(--primary500)" width="24" />
            }
            title="Válido nos melhores restaurantes"
            content="Com a mesma assinatura, seu desconto vale em todas as cidades disponíveis."
          />
          <Advantage
            icon={<Icon name="transfer-money" size={24} color="var(--gray500)" />}
            title="Desconto maior que seu investimento"
            content="Logo nos primeiros usos, seu desconto já é maior que o valor da assinatura."
          />
        </Content>
        <AssignBottomButton
          screenName="Duo Gourmet"
          isBenefit
          deeplink={location.state.deeplink}
          hasSubscription={location.state.hasSubscription}
          disabled={Boolean(location.state?.subscriptionData)}
        />
      </AppContainer>
    </>
  )
}
