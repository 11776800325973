import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.hero-image-content {
    padding: 0;
  }

  &:first-child {
    margin-bottom: 0;
  }
  & > * {
    width: 100%;
  }

  &:last-child {
    border-top: 1px solid var(--gray100);
  }
`

export const BoxTextAligment = styled.div`
  display: grid;
  gap: 1rem;
`

// export const Carousel = styled.div`
//   display: flex;
//   width: 100vw;
//   overflow: auto;
//   padding-left: 1.5rem;
//   margin-bottom: 1.5rem;

//   & button {
//     width: 100%;
//     min-width: 19rem;
//     margin-right: 0.75rem;
//     background: none;

//     & img {
//       width: 100%;
//       height: auto;
//     }
//   }
// `

export const Carousel = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  .slick-initialized .slick-slide {
    padding: 0 0rem;
  }

  .slick-dots li.slick-active button::before {
    color: var(--orange500);
  }

  & button {
    background: none;
  }

  & img {
    width: 100%;
    padding-right: 1rem;
  }
`
