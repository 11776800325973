import React from 'react'

import { interWbNavigate } from 'inter-webview-bridge'

import { Icon } from '@inter/inter-ui'

import { Container, HeaderIconButton, HeaderTitle } from './styles'
import useGoBack from '../../hooks/useGoBack'

type Props = {
  titleRoute: string
  goBackNative?: boolean
}

export const AppBar: React.FC<Props> = ({ titleRoute, goBackNative }) => {
  const goBack = useGoBack({ goBackNative })

  return (
    <Container>
      <HeaderIconButton onClick={goBack}>
        <Icon name="left-arrow" size={24} color="var(--orange500)" />
      </HeaderIconButton>

      <HeaderTitle
        className="title"
        color="typography"
        colorWeight={500}
        variant="headline-h3"
        semiBold
      >
        {titleRoute}
      </HeaderTitle>

      <HeaderIconButton onClick={() => interWbNavigate.requestGoBack()}>
        <Icon name="home" size={24} color="var(--orange500)" />
      </HeaderIconButton>
    </Container>
  )
}
