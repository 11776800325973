import styled from 'styled-components'

import { Text } from '@inter/inter-ui'

export const InterCelPlanBox = styled.div`
  width: 48%;
  min-width: 150px;
  margin-right: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  padding: 1rem 0.75rem;
`

export const InterCelPlanBoxAppsAvailable = styled.div`
  display: flex;
  height: 24px;
  margin-top: 0.5rem;
  padding: 0 0.5rem 0.5rem;
  justify-content: space-between;
  border-style: none none dashed;
  border-color: var(--gray200);

  & img {
    height: 100%;
  }
`

export const InterCelPlanBoxHeader = styled.div``
export const InterCelPlanBoxFooter = styled.div`
  display: flex;
  flex-direction: column;
`

export const InterCelPlanBoxDataPlan = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-big',
  semiBold: true,
})``

export const InterCelPlanBoxBenefits = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`

export const InterCelPlanBoxPrice = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'body-3',
  bold: true,
})`
  margin-bottom: 0.25rem;
`

export const InterCelPlanBoxCashback = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'caption-2',
  bold: true,
})`
  color: var(--success500);
`
