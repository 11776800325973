import React, { useCallback, useEffect, useState } from 'react'

import { Button, Text } from '@inter/inter-ui'
import { navigate, RouteComponentProps } from '@reach/router'

import { BoxTextAligment, Content, Condition, PlansCardList, PlanCard, Cashback } from './styles'
import InvestmentBlackIcon from '../../assets/icons/InvestmentBlack.svg'
import FreteBlackIcon from '../../assets/icons/FreteBlack.svg'
import CreditCardBlackIcon from '../../assets/icons/CreditCardBlack.svg'
import { AppBar } from '../../components/AppBar'
import { Home } from '../Home'
import useAsync from '../../hooks/useAsync'
import { AppContainer } from '../../styles/GlobalStyles'
import { bridgeNative } from '../../App'
import { SubscriptionsWebview } from '../../types/Subscription'

interface CancelamentoProps extends RouteComponentProps {
  subscriptionId?: string
}

type WebviewResponse = {
  hasSubscription: boolean
  subscriptions: SubscriptionsWebview[]
  termsOfUse: string
}

type CancelDataPage = {
  imageUrl: string
  title: string
  subtitle: string
  cancellation: Cancellation
}

type Cancellation = {
  title: string
  subtitle: string
  reasons: Reason[]
}

export interface Reason {
  id: string
  iconUrl: string
  text: string
}

export const Cancelamento: React.FC<CancelamentoProps> = ({ subscriptionId }) => {
  const [response, setResponse] = useState<WebviewResponse | null | undefined>(null)
  const [cancelDataPage, getCancelDataPage] = useAsync<CancelDataPage>('get')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const cancelInterPass = useCallback(() => {
    bridgeNative.execute({
      action: 'sendToCancelSubscription',
      metadata: {
        productId: 'INTER_PRIME',
        subscriptionId,
      },
    })
  }, [subscriptionId])

  const fetchSubscribeData = useCallback(async () => {
    const res: WebviewResponse = await bridgeNative.execute({
      action: 'requestSubscriptionPages',
      metadata: { productId: 'INTER_PRIME' },
    })
    setResponse(res)
  }, [])

  const fetchCancelDataPage = useCallback(async () => {
    await getCancelDataPage(`app-pf/subscription/v1/subscriptions/${subscriptionId}/cancel`)
  }, [getCancelDataPage, subscriptionId])

  useEffect(() => {
    fetchSubscribeData()
    fetchCancelDataPage()
  }, [fetchSubscribeData, fetchCancelDataPage])

  const backToHome = useCallback(() => {
    navigate('/')
  }, [])

  if (response?.subscriptions[0].status === 'CANCELED') {
    return <Home />
  }
  return (
    <>
      <AppBar titleRoute="Cancelamento" />
      <AppContainer paddingTopRem={5}>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
              Ao cancelar, você para de ganhar cashbacks Inter Pass
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Com o Inter Pass, você tem mais cashback em vários produtos no Inter. Cancelando sua
              assinatura hoje, você deixa de ter esses benefícios em{' '}
              {cancelDataPage.isSuccess &&
                cancelDataPage.data.subtitle
                  .match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g)
                  ?.find((e) => e)}
              . Confira o que muda:
            </Text>
          </BoxTextAligment>
        </Content>
        <PlansCardList>
          <PlanCard>
            <div>
              <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
                Inter Cel
              </Text>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1.25rem',
                  marginBottom: '0.5rem',
                }}
              >
                <Text color="typography" colorWeight={400} variant="caption-2">
                  Cancelando o plano: R$75,50
                </Text>

                <Cashback>
                  <Text color="typography" colorWeight={500} variant="caption-2" bold>
                    R$7,50 de cashback
                  </Text>
                </Cashback>
              </div>
              <hr
                style={{
                  borderStyle: 'none none dashed',
                  color: 'var(--gray200)',
                  marginBottom: '0.525rem',
                }}
              />
              <Text color="typography" colorWeight={400} variant="caption-2">
                Você deixará de ganhar: R$7,50
              </Text>
            </div>
          </PlanCard>
          <PlanCard>
            <div>
              <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
                Seguro de vida
              </Text>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1.25rem',
                  marginBottom: '0.5rem',
                }}
              >
                <Text color="typography" colorWeight={400} variant="caption-2">
                  Cancelando o plano: R$50,00
                </Text>

                <Cashback>
                  <Text color="typography" colorWeight={500} variant="caption-2" bold>
                    R$10,00 de cashback
                  </Text>
                </Cashback>
              </div>
              <hr
                style={{
                  borderStyle: 'none none dashed',
                  color: 'var(--gray200)',
                  marginBottom: '0.525rem',
                }}
              />
              <Text color="typography" colorWeight={400} variant="caption-2">
                Você deixará de ganhar: R$10,00
              </Text>
            </div>
          </PlanCard>
          <PlanCard>
            <div>
              <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
                Inter Duo Gourmet
              </Text>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1.25rem',
                  marginBottom: '0.5rem',
                }}
              >
                <Text color="typography" colorWeight={400} variant="caption-2">
                  Cancelando o plano: R$450,00
                </Text>

                <Cashback>
                  <Text color="typography" colorWeight={500} variant="caption-2" bold>
                    R$45,00 de cashback
                  </Text>
                </Cashback>
              </div>
              <hr
                style={{
                  borderStyle: 'none none dashed',
                  color: 'var(--gray200)',
                  marginBottom: '0.525rem',
                }}
              />
              <Text color="typography" colorWeight={400} variant="caption-2">
                Você deixará de ganhar: R$45,00
              </Text>
            </div>
          </PlanCard>
          <PlanCard>
            <div>
              <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
                Seguro Cartão Protegido
              </Text>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1.25rem',
                  marginBottom: '0.5rem',
                }}
              >
                <Text color="typography" colorWeight={400} variant="caption-2">
                  Cancelando o plano: Grátis
                </Text>

                <Cashback>&nbsp;</Cashback>
              </div>
              <hr
                style={{
                  borderStyle: 'none none dashed',
                  color: 'var(--gray200)',
                  marginBottom: '0.525rem',
                }}
              />
              <Text color="typography" colorWeight={400} variant="caption-2">
                Você deixará de ganhar: R$1,90
              </Text>
            </div>
          </PlanCard>
        </PlansCardList>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
              Você também deixa de aproveitar os benefícios
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Conheça alguns benefícios que você garante com sua assinatura:
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <Condition>
            <div>
              <img
                src={FreteBlackIcon}
                alt="ícone de smartphone"
                color="var(--primary500)"
                width="24"
              />
            </div>
            <Text color="typography" colorWeight={400} variant="body-3" bold>
              Até 100% de cashback no frete para produtos do Inter Shop
            </Text>
          </Condition>

          <Condition>
            <img
              src={CreditCardBlackIcon}
              alt="ícone de smartphone"
              color="var(--primary500)"
              width="24"
            />
            <Text color="typography" colorWeight={400} variant="body-3" bold>
              0,25% de cashback adicional na fatura do cartão de crédito Inter
            </Text>
          </Condition>

          <Condition>
            <div>
              <img
                src={InvestmentBlackIcon}
                alt="ícone de localização"
                color="var(--primary500)"
                width="24"
              />
            </div>
            <Text color="typography" colorWeight={400} variant="body-3" bold>
              Lives e relatórios exclusivos sobre investimentos e o mercado de ações
            </Text>
          </Condition>
        </Content>
        <Content>
          <Button style={{ width: '100%', marginBottom: '0.5rem' }} onClick={cancelInterPass}>
            Cancelar
          </Button>
          <Button variant="secondary" style={{ width: '100%' }} onClick={backToHome}>
            Voltar
          </Button>
        </Content>
      </AppContainer>
    </>
  )
}
