import styled from 'styled-components'

import { Text } from '@inter/inter-ui'

export const InterPassPlanBox = styled.div`
  width: 48%;
  min-width: 48%;
  margin-right: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  padding: 1rem;
  &:last-child {
    margin-right: 0;
  }
`
export const InterPassPlanBoxHeader = styled.div``
export const InterPassPlanBoxFooter = styled.div``

export const InterPassPlanBoxFooterAssign = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: space-between;
`

export const TrialDaysTag = styled.div`
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;
  background: var(--success100);
  font-size: 10px;
  display: table;
  margin-bottom: 0.5rem;

  &.cashback {
    background: var(--success500);
    & span {
      color: var(--white100);
    }
  }
`

export const CashbackTag = styled.div`
  & span {
    color: var(--success500);
  }
  margin-bottom: 0.5rem;
`

export const InterPassPlanBoxDescription = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-h3',
  semiBold: true,
})``
