import styled from 'styled-components'

export const AssignBottomButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  background: var(--white100);
  border-top: 1px solid var(--gray100);

  & > * {
    width: 100%;
  }
`
