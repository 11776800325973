import React, { useEffect } from 'react'

import { Icon, Text } from '@inter/inter-ui'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'

import {
  BoxTextAligment,
  Content,
  DeliveryCashback,
  AdvantageBox,
  AdvantageBoxIcon,
  AdvantageBoxContent,
  AdvantageBoxText,
  AdvantageBoxTitle,
  Carousel,
  ToolsBox,
  ToolsBoxContent,
  ToolsBoxIcon,
} from './styles'
import HeroImage from './assets/images/HeroImageInvestPro.png'
import CashbackIcon from '../../assets/icons/Cashback.svg'
import CashbackBlackIcon from '../../assets/icons/CashbackBlack.svg'
import AtomLogo from '../../assets/images/tools/AtomLogo.svg'
import NeologicaLogo from '../../assets/images/tools/NeologicaLogo.svg'
import TrydLogo from '../../assets/images/tools/TrydLogo.svg'
import IRTradeLogo from '../../assets/images/tools/IRTradeLogo.svg'
import BussolaInvestidorLogo from '../../assets/images/tools/BussolaInvestidorLogo.svg'
import HBProLogo from '../../assets/images/tools/HBProLogo.svg'
import InterTrackLogo from '../../assets/images/tools/InterTrackLogo.svg'
import Carousel1 from './assets/carousel-1.png'
import Carousel2 from './assets/carousel-2.png'
import Carousel3 from './assets/carousel-3.png'
import GiftBlackIcon from '../../assets/icons/GiftBlack.svg'
import { AppBar } from '../../components/AppBar'
import { AppContainer } from '../../styles/GlobalStyles'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { SubscriptionsWebview } from '../../types/Subscription'

export const InvestPro: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const location = useLocation() as WindowLocation<{
    hasSubscription: boolean
    deeplink: string
    subscriptionData?: SubscriptionsWebview
  }>

  return (
    <>
      <AppBar titleRoute="Invest Pro no Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              20% de cashback na assinatura do Invest Pro
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Aqui no Inter, você já investe sem nenhuma taxa. E com apenas R$ 1,70 por dia, você
              pode acessar mais ferramentas e conteúdos exclusivos pra potencializar seus
              rendimentos.
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <DeliveryCashback>
            <div>
              <img src={CashbackIcon} alt="Cashback ícone" />
            </div>
            <div>
              <Text color="typography" colorWeight={400} variant="body-3" bold>
                Você ganhará R$ 9,98 de cashback na assinatura sendo Inter Pass
              </Text>
            </div>
          </DeliveryCashback>
        </Content>
        <Content>
          <BoxTextAligment style={{ gap: '0.5rem' }}>
            <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
              Conheça os benefícios
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Assinando o Invest Pro, você tem acesso exclusivo a todas essas vantagens:
            </Text>
          </BoxTextAligment>
        </Content>
        <Content style={{ marginBottom: '1rem' }}>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Investimentos exclusivos
          </Text>
        </Content>
        <Carousel>
          <div>
            <img src={Carousel1} alt="imagem do Carrossel" />
          </div>
          <div>
            <img src={Carousel2} alt="imagem do Carrossel" />
          </div>
          <div>
            <img src={Carousel3} alt="imagem do Carrossel" />
          </div>
        </Carousel>
        <Content>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Ferramentas
          </Text>
          <div style={{ width: '100%' }}>
            <ToolsBox>
              <ToolsBoxIcon>
                <img src={AtomLogo} alt="Atom logo" />
              </ToolsBoxIcon>
              <ToolsBoxContent>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  Atom
                </Text>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Hub de informações e cotação com consolidação de portfólio
                </Text>
                <Text
                  color="typography"
                  colorWeight={300}
                  variant="caption-1"
                  bold
                  style={{ textDecoration: 'line-through' }}
                >
                  R$ 215,00 Incluso na assinatura
                </Text>
              </ToolsBoxContent>
            </ToolsBox>
            <ToolsBox>
              <ToolsBoxIcon>
                <img src={NeologicaLogo} alt="Neologica logo" />
              </ToolsBoxIcon>
              <ToolsBoxContent>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  Neologica
                </Text>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Trading de alta performance, com análises gráficas e técnicas pra perfis
                  analíticos e operacionais
                </Text>
                <Text
                  color="typography"
                  colorWeight={300}
                  variant="caption-1"
                  bold
                  style={{ textDecoration: 'line-through' }}
                >
                  R$ 215,00 Incluso na assinatura
                </Text>
              </ToolsBoxContent>
            </ToolsBox>
            <ToolsBox>
              <ToolsBoxIcon>
                <img src={TrydLogo} alt="Tryd logo" />
              </ToolsBoxIcon>
              <ToolsBoxContent>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  Tryd
                </Text>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Plataforma de negociação inovadora, voltada para o Trader profisisonal
                </Text>
                <Text
                  color="typography"
                  colorWeight={300}
                  variant="caption-1"
                  bold
                  style={{ textDecoration: 'line-through' }}
                >
                  R$ 215,00 Incluso na assinatura
                </Text>
              </ToolsBoxContent>
            </ToolsBox>
            <ToolsBox>
              <ToolsBoxIcon>
                <img src={IRTradeLogo} alt="IR Trade logo" />
              </ToolsBoxIcon>
              <ToolsBoxContent>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  IR Trade
                </Text>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Contabilidade especializada em Bolsa, IR e Regularização Fiscal
                </Text>
                <Text
                  color="typography"
                  colorWeight={300}
                  variant="caption-1"
                  bold
                  style={{ textDecoration: 'line-through' }}
                >
                  R$ 215,00 Incluso na assinatura
                </Text>
              </ToolsBoxContent>
            </ToolsBox>
            <ToolsBox>
              <ToolsBoxIcon>
                <img src={BussolaInvestidorLogo} alt="Bussola Investidor logo" />
              </ToolsBoxIcon>
              <ToolsBoxContent>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  Bússola do Investidor
                </Text>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Calculadora de IR na Bolsa, carteira de investimentos e informação de valores
                </Text>
                <Text
                  color="typography"
                  colorWeight={300}
                  variant="caption-1"
                  bold
                  style={{ textDecoration: 'line-through' }}
                >
                  R$ 215,00 Incluso na assinatura
                </Text>
              </ToolsBoxContent>
            </ToolsBox>
            <ToolsBox>
              <ToolsBoxIcon>
                <img src={HBProLogo} alt="HB Pro logo" />
              </ToolsBoxIcon>
              <ToolsBoxContent>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  HB Pro
                </Text>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Gráficos em tempo real e informações detalhadas no seu Home Broker
                </Text>
                <Text
                  color="typography"
                  colorWeight={300}
                  variant="caption-1"
                  bold
                  style={{ textDecoration: 'line-through' }}
                >
                  R$ 215,00 Incluso na assinatura
                </Text>
              </ToolsBoxContent>
            </ToolsBox>
            <ToolsBox>
              <ToolsBoxIcon>
                <img src={InterTrackLogo} alt="Inter Track logo" />
              </ToolsBoxIcon>
              <ToolsBoxContent>
                <Text color="typography" colorWeight={500} variant="body-3" bold>
                  Inter Track Pro
                </Text>
                <Text color="typography" colorWeight={400} variant="caption-1">
                  Acompanhe o mercado de ações e o histórico de preços do ativo
                </Text>
                <Text
                  color="typography"
                  colorWeight={300}
                  variant="caption-1"
                  bold
                  style={{ textDecoration: 'line-through' }}
                >
                  R$ 215,00 Incluso na assinatura
                </Text>
              </ToolsBoxContent>
            </ToolsBox>
          </div>
        </Content>
        <Content style={{ marginBottom: '6.5rem' }}>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Vantagens exclusivas
          </Text>
          <div style={{ width: '100%' }}>
            <AdvantageBox>
              <AdvantageBoxIcon>
                <img
                  src={GiftBlackIcon}
                  alt="ícone de presente"
                  color="var(--primary500)"
                  width="24"
                />
              </AdvantageBoxIcon>
              <AdvantageBoxContent>
                <AdvantageBoxTitle>
                  <Text color="typography" colorWeight={500} variant="body-3" bold>
                    2 meses grátis
                  </Text>
                </AdvantageBoxTitle>
                <AdvantageBoxText>
                  <Text color="typography" colorWeight={400} variant="body-3">
                    Todos os assinantes ganham 2 meses grátis de Invest Pro. Aproveite!
                  </Text>
                </AdvantageBoxText>
              </AdvantageBoxContent>
            </AdvantageBox>
            <AdvantageBox>
              <AdvantageBoxIcon>
                <Icon name="transfer-money" size={24} color="var(--gray500)" />
              </AdvantageBoxIcon>
              <AdvantageBoxContent>
                <AdvantageBoxTitle>
                  <Text color="typography" colorWeight={500} variant="body-3" bold>
                    Resgate express
                  </Text>
                </AdvantageBoxTitle>
                <AdvantageBoxText>
                  <Text color="typography" colorWeight={400} variant="body-3">
                    Faça o resgate antecipado dos recursos das suas operações na bolsa.
                  </Text>
                </AdvantageBoxText>
              </AdvantageBoxContent>
            </AdvantageBox>
            <AdvantageBox>
              <AdvantageBoxIcon>
                <img
                  src={CashbackBlackIcon}
                  alt="ícone de cashback"
                  color="var(--primary500)"
                  width="24"
                />
              </AdvantageBoxIcon>
              <AdvantageBoxContent>
                <AdvantageBoxTitle>
                  <Text color="typography" colorWeight={500} variant="body-3" bold>
                    Cashback de câmbio
                  </Text>
                </AdvantageBoxTitle>
                <AdvantageBoxText>
                  <Text color="typography" colorWeight={400} variant="body-3">
                    Tenha acesso as melhores cotações de câmbio do mercado e ainda receba cashback
                    direto na sua conta.
                  </Text>
                </AdvantageBoxText>
              </AdvantageBoxContent>
            </AdvantageBox>
          </div>
        </Content>
        <AssignBottomButton
          screenName="Invest Pro"
          isBenefit
          deeplink={location.state.deeplink}
          hasSubscription={location.state.hasSubscription}
          disabled={Boolean(location.state?.subscriptionData)}
        />
      </AppContainer>
    </>
  )
}
