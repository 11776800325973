import React, { useEffect } from 'react'

import InterWebviewBridge, { interWbSession } from 'inter-webview-bridge'

import { initTheme, Theme } from '@inter/inter-ui'
import '@inter/inter-ui/dist/inter-ui.css'
import { Router } from '@reach/router'

import { Home } from './pages/Home'
import { InterShop } from './pages/InterShop'
import { Cartoes } from './pages/Cartoes'
import { MaisProtegido } from './pages/MaisProtegido'
import { Seguros } from './pages/Seguros'
import { DuoGourmet } from './pages/DuoGourmet'
import { InterCel } from './pages/InterCel'
import { InvestPro } from './pages/InvestPro'
import { HomeSigned } from './pages/HomeSigned'
import { Cancelamento } from './pages/Cancelamento'
import { LandingPage } from './pages/LandingPage'
import { Investimentos } from './pages/Investimentos'
import { CompleteSigned } from './pages/CompleteSigned'

export const bridgeNative = InterWebviewBridge.getInstance()

function App() {
  useEffect(() => {
    interWbSession.editToolbar('', false)
    initTheme(Theme.PF)
  }, [])
  return (
    <div className="App">
      <Router>
        <Home path="/" />
        <LandingPage path="landing-page" />
        <InterShop path="inter-shop" />
        <Cartoes path="cartoes" />
        <MaisProtegido path="mais-protegido" />
        <Seguros path="seguros" />
        <DuoGourmet path="duo-gourmet" />
        <InterCel path="inter-cel" />
        <InvestPro path="invest-pro" />
        <Investimentos path="investimentos" />
        <HomeSigned path="home-signed" />
        <Cancelamento path="cancelamento/:subscriptionId" />
        <CompleteSigned path="complete-signed" />
      </Router>
    </div>
  )
}

export default App
