import styled from 'styled-components'

import { Text } from '@inter/inter-ui'

export const AdvantageBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
`

export const AdvantageBoxIcon = styled.div`
  display: flex;
  align-items: flex-start;
`

export const AdvantageBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1.5rem;
`

export const AdvantageBoxTitle = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'body-3',
  bold: true,
})`
  margin-bottom: 0.25rem;
`

export const AdvantageBoxText = styled(Text).attrs({
  color: 'typography',
  colorWeight: 400,
  variant: 'body-3',
})``
