import React, { useEffect } from 'react'

import Slider from 'react-slick'

import { Text } from '@inter/inter-ui'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'

import { BoxTextAligment, Content, Carousel } from './styles'
import HeroImage from './assets/images/HeroImageInvestimentos.png'
import EducationHatBlackIcon from '../../assets/icons/EducationHatBlack.svg'
import DepositTicketBlackIcon from '../../assets/icons/DepositTicketBlack.svg'
import Carousel1 from './assets/images/carousel-1.png'
import Carousel2 from './assets/images/carousel-2.png'
import { AppBar } from '../../components/AppBar'
import { AppContainer } from '../../styles/GlobalStyles'
import { Advantage } from '../../components/Advantage'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { SubscriptionsWebview } from '../../types/Subscription'

export const Investimentos: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
  }

  const location = useLocation() as WindowLocation<{
    hasSubscription: boolean
    deeplink: string
    subscriptionData?: SubscriptionsWebview
  }>
  return (
    <>
      <AppBar titleRoute="Investimentos no Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              Lives e relatórios de investimento exclusivos
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Assinantes Inter Pass têm acesso a conteúdos exclusivos, lives e relatórios de
              pesquisas completas para investir ainda melhor.
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <BoxTextAligment style={{ gap: '0.5rem' }}>
            <Text color="typography" colorWeight={500} variant="headline-h2" semiBold>
              Conheça os benefícios
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Com o Inter Pass, você garante benefícios para melhorar seus investimentos.
            </Text>
          </BoxTextAligment>
        </Content>
        {/* <Carousel>
          <button type="button">
            <img src={Carousel1} alt="imagem do Carrossel" />
          </button>
          <button type="button">
            <img src={Carousel2} alt="imagem do Carrossel" />
          </button>
        </Carousel> */}
        <Carousel>
          <Slider {...settings} centerMode centerPadding="24px" nextArrow={<></>} prevArrow={<></>}>
            <button type="button">
              <img src={Carousel1} alt="imagem do Carrossel" />
            </button>
            <button type="button">
              <img src={Carousel2} alt="imagem do Carrossel" />
            </button>
          </Slider>
        </Carousel>
        <Content style={{ marginBottom: '6.5rem' }}>
          <Text
            color="typography"
            colorWeight={500}
            variant="headline-h3"
            semiBold
            style={{ marginBottom: '0.5rem' }}
          >
            Conteúdos exclusivos
          </Text>
          <Advantage
            icon={
              <img
                src={EducationHatBlackIcon}
                alt="ícone de capelo"
                color="var(--primary500)"
                width="24"
              />
            }
            title="Lives especiais"
            content="Assista e saiba tudo sobre o mundo dos investimentos com a economista
                especialista Rafaela de Oliveira."
          />
          <Advantage
            icon={
              <img
                src={DepositTicketBlackIcon}
                alt="ícone de relatório"
                color="var(--primary500)"
                width="24"
              />
            }
            title="Relatórios de pesquisas"
            content="Receba análises mensais produzidas pelos especialistas do Inter com os assuntos
                mais relevantes do mês."
          />
        </Content>
        <AssignBottomButton
          screenName="Investimentos"
          isBenefit
          deeplink={location.state.deeplink}
          hasSubscription={location.state.hasSubscription}
          disabled={Boolean(location.state?.subscriptionData)}
        />
      </AppContainer>
    </>
  )
}
