import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.hero-image-content {
    padding: 0;
  }

  & > * {
    width: 100%;
  }

  &:last-child {
    padding-top: 1rem;
    border-top: 1px solid var(--gray100);
  }
`

export const BoxTextAligment = styled.div`
  display: grid;
  gap: 1rem;
`

export const Cashback = styled.div`
  & span {
    color: var(--success500);
    text-decoration: line-through;
  }
`

export const Condition = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 1rem;
  }

  & > :first-child {
    margin-right: 1.5rem;
  }
`

export const PlansCardList = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
`

export const PlanCard = styled.div`
  width: 48%;
  min-width: 150px;
  margin-right: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
`
