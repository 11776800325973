import styled from 'styled-components'

import { Link } from '@reach/router'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.hero-image-content {
    padding: 0;
  }

  &:first-child {
    margin-bottom: 0;
  }
  & > * {
    width: 100%;
  }

  &:last-child {
    border-top: 1px solid var(--gray100);
  }
`

export const BoxTextAligment = styled.div`
  display: grid;
  gap: 1rem;
`

export const PlansCardList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  overflow: auto;
`

export const BenefitBox = styled(Link)`
  width: 100%;
  display: flex;
  border: 0px solid var(--gray100);
  border-bottom-width: 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  text-decoration: none;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    border-width: 0;
  }
`

export const BenefitBoxIcon = styled.div`
  background-color: var(--primary100);
  display: flex;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 4rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
`

export const BenefitBoxContent = styled.div`
  display: grid;
  padding: 0rem 0.875rem;
  gap: 0.5rem;
  width: 100%;
`

export const BackgroundPending = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: var(--alert100);
  border-radius: 0.5rem;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  margin-top: 1rem;

  .alert-text {
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
    flex-direction: column;
  }
`
