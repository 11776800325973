import React, { useEffect } from 'react'

import { Text } from '@inter/inter-ui'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'

import { BoxTextAligment, Content, CardCashback } from './styles'
import HeroImage from './assets/images/HeroImageCartoes.png'
import { AppBar } from '../../components/AppBar'
import { AppContainer } from '../../styles/GlobalStyles'
import { AssignBottomButton } from '../../components/AssignBottomButton'
import { SubscriptionsWebview } from '../../types/Subscription'

export const Cartoes: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const location = useLocation() as WindowLocation<{
    hasSubscription: boolean
    deeplink: string
    subscriptionData?: SubscriptionsWebview
  }>

  return (
    <>
      <AppBar titleRoute="Cashback no Inter Pass" />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              Cashback turbinado nos cartões Inter
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Sempre que você pagar a fatura do seu cartão Mastercard Inter, você recebe cashback na
              sua Conta Digital Inter. Basta fazer o pagamento do valor total e dentro da data de
              vencimento. Assinantes do Inter Pass têm{' '}
              <strong style={{ fontWeight: 'bold' }}>+0,25% de cashback</strong> em todos os cartões
              Inter pessoa física.
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <CardCashback>
            <div>
              <Text color="typography" colorWeight={300} variant="body-3" bold>
                cashback do seu cartão
              </Text>
            </div>
            <div>
              <Text color="primary" colorWeight={500} variant="headline-h2" semiBold>
                +0,25%
              </Text>
            </div>
          </CardCashback>
        </Content>
        <Content>
          <Text color="typography" colorWeight={400} variant="body-3">
            Receba +0,25% de cashback em todos os cartões Inter pessoa física sobre o valor das
            compras lançadas na fatura.
          </Text>
        </Content>
        <Content>
          <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
            Condições
          </Text>
        </Content>
        <Content style={{ marginBottom: '7rem' }}>
          <BoxTextAligment>
            <Text color="typography" colorWeight={400} variant="body-3">
              O benefício de 0,25% será concedido por fatura aos clientes que atenderem às seguintes
              condições:
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Pagamento integral da fatura em dia; Sem adesão de parcelamento de fatura,
              parcelamento compulsório e crédito rotativo; Adesão ao débito automático.
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Válido para todos os cartões Inter. Para clientes Mastercard Platinum e Mastercard
              Black o cashback continua seguindo as condições de cashback padrão estabelecidas. Para
              clientes Win, não há valor máximo no cashback relativo aos 0,25% adicionais.
            </Text>
          </BoxTextAligment>
        </Content>
        <AssignBottomButton
          screenName="Cartões"
          isBenefit
          deeplink={location.state.deeplink}
          hasSubscription={location.state.hasSubscription}
          disabled={Boolean(location.state?.subscriptionData)}
        />
      </AppContainer>
    </>
  )
}
